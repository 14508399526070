import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../features/stores";
import { useSelector } from "react-redux";
import { ProjectData } from "../../../features/interface/proyek.interface";
import { updateProjectData } from "../../../features/reducers/proyekSlice";
import TransactionProyek from "./transaksi-proyek/transaksi/tableData";
import SaldoProyek from "./saldo-proyek/index";

const EditProyek: React.FC = () => {
  const [projectName, setProjectName] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const token = localStorage.getItem("token");
  const { projectData, selectedProjectId } = useSelector((state: RootState) => state.project);
  const [project, setProject] = useState<ProjectData | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (selectedProjectId) {
      const selectedProject = projectData?.find((proj) => proj._id === selectedProjectId);
      if (selectedProject) {
        setProject(selectedProject);
        setProjectName(selectedProject.nama_project);
        setCallbackUrl(selectedProject.callback_url);
        setSiteUrl(selectedProject.website_url);
      }
    }
  }, [selectedProjectId, projectData]);

  useEffect(() => {
    if (project) {
      const isChanged = projectName !== project.nama_project || callbackUrl !== project.callback_url || siteUrl !== project.website_url;
      setHasChanges(isChanged);
    }
  }, [projectName, callbackUrl, siteUrl, project]);

  const handleSave = async () => {
    if (!token || !selectedProjectId || isSaving || !hasChanges) return;

    setIsSaving(true);
    try {
      await dispatch(
        updateProjectData({
          id: selectedProjectId,
          token,
          updatedData: {
            nama_project: projectName,
            callback_url: callbackUrl,
            website_url: siteUrl,
          },
        })
      ).unwrap();
      navigate("/me/proyek");
    } catch (error) {
      console.error("Error updating project:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    navigate("/me/proyek");
  };

  return (
    <section>
      <div className="flex w-full flex-col mb-7 space-y-6 py-[40px]">
        <div className="border border-[#DDE5E9] rounded-2xl ps-10 pe-36 py-8 w-full">
          <div className="text-start">
            <label htmlFor="projectName" className="text-[#222831] text-sm font-medium">
              Nama Project
            </label>
            <input
              type="text"
              id="projectName"
              placeholder="Masukan nama project kamu Cth : jualanku"
              className="w-full bg-[#F9F9FA] placeholder:text-[#D9D9D9] px-3 py-4 mt-2 rounded-2xl outline-none uppercase cursor-pointer"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              readOnly
            />
          </div>

          <div className="flex justify-start gap-12 mt-6">
            <div className="text-start w-full">
              <label htmlFor="callbackUrl" className="text-[#222831] text-sm font-medium">
                Callback URL
              </label>
              <input
                type="text"
                id="callbackUrl"
                placeholder="Masukkan Url Callback Cth : www.project.com/Callback"
                className="w-full bg-[#F9F9FA] placeholder:text-[#D9D9D9] text-sm px-3 py-4 mt-2 rounded-2xl outline-none"
                value={callbackUrl}
                onChange={(e) => setCallbackUrl(e.target.value)}
              />
            </div>

            <div className="text-start w-full">
              <label htmlFor="siteUrl" className="text-[#222831] text-sm font-medium">
                Site URL
              </label>
              <input
                type="text"
                id="siteUrl"
                placeholder="Masukkan Website URL Cth : www.project.com"
                className="w-full bg-[#F9F9FA] placeholder:text-[#D9D9D9] text-sm px-3 py-4 mt-2 rounded-2xl outline-none"
                value={siteUrl}
                onChange={(e) => setSiteUrl(e.target.value)}
              />
            </div>
          </div>

          <div className="flex gap-[40px]">
            <button className="px-24 py-3 mt-16 rounded-full flex justify-start font-medium border text-[#FF5722] border-[#FF5722]" onClick={handleBack}>
              Kembali
            </button>

            <button
              className={`px-24 py-3 mt-16 rounded-full flex justify-start font-medium ${!hasChanges || isSaving ? "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed" : "bg-[#FF5722] text-white"}`}
              onClick={handleSave}
              disabled={!hasChanges || isSaving}>
              {isSaving ? "Menyimpan..." : "Simpan"}
            </button>
          </div>
        </div>
      </div>
      <div>
        <SaldoProyek projectId={project?._id || null} />
      </div>
      <div>
        <TransactionProyek projectId={project?._id || null} />
      </div>
    </section>
  );
};

export default EditProyek;
