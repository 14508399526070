import React, { useState } from "react";
import axios from "axios";
import Bgregis from "../../assets/images/bg-regis.png";
import Back from "../../assets/images/back.png";
import Google from "../../assets/images/google.png";
import { arrowauth, arrowdown, tsreuauth, eyeIcon, eyeSlashIcon } from "../../assets/icon";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import PopupLupaPassword from "./popupFP";
import OtpPopup from "./popupOtp";

type LegalitasType = "individu" | "perusahaan";

const Login: React.FC = () => {
  const [legalitas, setLegalitas] = useState<LegalitasType>("individu");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [userId, setUserId] = useState("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isOtpPopupOpen, setIsOtpPopupOpen] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  // const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLegalitasChange = (value: LegalitasType) => {
    setLegalitas(value);
    setIsDropdownOpen(false);
    setErrorMessage(null); // Reset error message when user types in password input
    setPasswordError(false); // Reset password error when user types in password input
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[?=.*?[#?!@$%^&*-])[A-Za-z\d?=.*?[#?!@$%^&*-]{8,}$/;
    return passwordRegex.test(password);
  };

  React.useEffect(() => {
    const isValid = validateEmail(email) && validatePassword(password);
    setIsFormValid(isValid);
  }, [email, password]);

  // Function to handle email input change and reset errorMessage
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessage(null); // Reset error message when user types in email input
    setEmailError(false); // Reset email error when user types in email input
  };

  // Function to handle password input change and reset errorMessage
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorMessage(null); // Reset error message when user types in password input
    setPasswordError(false); // Reset password error when user types in password input
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}login-user`,
        {
          email,
          password,
          legalitas,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Set OTP Popup and User ID
      setIsOtpPopupOpen(true);
      setUserId(response.data.user_id);

      // const { token } = response.daa;
      // localStorage.setItem("token", token);

      // Show OTP Popup
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response?.data?.message || "";
        setErrorMessage(errorResponse);

        if (errorResponse.includes("Email")) {
          // setEmailError(true);
        } else {
          setEmailError(false);
        }

        if (errorResponse.includes("Password")) {
          setPasswordError(true);
        } else {
          setPasswordError(false);
        }
      } else {
        // setErrorMessage("Terjadi kesalahan yang tidak terduga");
        setEmailError(false);
        setPasswordError(false);
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // const handleOtpSubmit = (otp: string) => {
  //   // Assuming OTP verification is done here

  //   // After successful OTP verification, navigate to the dashboard
  //   navigate("/me/beranda");
  // };

  return (
    <section className=" bg-[#1A1A1A] border-[5px] rounded-2xl m-4 px-8 py-14 font-inter  ">
      <div className="w-10 h-10 mb-10 -mt-5 cursor-pointer">
        <Link to="/">
          <img src={Back} alt="back" />
        </Link>
      </div>
      <div className="lg:flex justify-center items-center gap-24 ">
        <div className="flex flex-col justify-center ">
          <div className="flex justify-center">
            <img src={Bgregis} alt="Registrasi" />
          </div>
          <div className="hidden xl:flex justify-end  -mt-32 lg:-mt-32 mb-5">{arrowauth()}</div>
          <div className="mb-5 lg:w-[450px] xl:w-[580px] px-8 py-4 rounded-3xl bg-[#ffffff06] justify-center">
            <div className="text-[25px] lg:text-[32px] lg:mt-4 xl:text-[40px] text-white font-bold">
              <div className="hidden lg:block lg:-ms-4 xl:-ms-1 -mb-6">{tsreuauth()}</div>
              <div className="md:-mb-4 "> Selamat Datang Kembali</div>
              <div className="">
                <p className="">
                  <span>Di</span>
                  <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text ms-2"> Ultra Digital Indonesia</span>
                </p>
              </div>
            </div>
            <div className="text-base  text-white mb-2 mt-6">
              Masuk untuk mengakses dashboard pembayaran Anda. Nikmati kemudahan mengelola transaksi dengan platform yang aman, cepat, dan terintegrasi. Udin memastikan setiap transaksi Anda berjalan lancar dan efisien. Selamat
              bertransaksi!
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[513px] bg-white rounded-3xl px-[20px] md:px-[50px] py-8 text-start">
          <h1 className="text-[20px] md:text-[25px] xl:text-[31px] my-3 font-bold lg:text-center md:text-start ">Selamat Datang Kembali</h1>
          <form className="flex flex-col gap-3 " onSubmit={handleLogin}>
            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">Pilih Legalitas</span>
              <div
                className="cursor-pointer appearance-none bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-[#EF2725] transition-all capitalize"
                onClick={toggleDropdown}>
                {legalitas}
                <span className={`ml-2 transition-transform ${isDropdownOpen ? "rotate-180" : "rotate-0"}`}>{arrowdown()}</span>
              </div>
              {isDropdownOpen && (
                <ul className=" mt-1 w-full bg-white  py-1 ">
                  <li className="text-sm py-3 px-3 hover:bg-[#E8EEF8] rounded cursor-pointer" onClick={() => handleLegalitasChange("individu")}>
                    Individu
                  </li>
                  <li className="text-sm py-3 px-3 hover:bg-[#E8EEF8] rounded cursor-pointer" onClick={() => handleLegalitasChange("perusahaan")}>
                    Perusahaan
                  </li>
                </ul>
              )}
            </label>
            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">{legalitas === "perusahaan" ? "Email Perusahaan" : "Email"}</span>
              <input
                type="email"
                className="bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm outline-none"
                placeholder={legalitas === "perusahaan" ? "Contoh: perusahaan@gmail.com" : "Contoh: ahmadjaidi@gmail.com"}
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <span className="text-red-500 text-sm mt-1">Email tidak valid.</span>}
            </label>
            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">Password</span>
              <div className="relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  className={`bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm w-full outline-none ${errorMessage ? "border border-red-500" : ""}`}
                  placeholder="Masukkan password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <span className="absolute inset-y-0 right-0 pr-4 mt-2 flex items-center cursor-pointer" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                  {isPasswordVisible ? eyeIcon() : eyeSlashIcon()}
                </span>
              </div>

              {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}

              {passwordError && <span className="text-red-500 text-sm mt-1"></span>}
              <span className="text-xs text-gray-500">Password memiliki minimal 8 karakter, termasuk huruf, angka, dan </span>
              <div className="flex justify-between">
                <p className="text-xs text-gray-500">simbol</p>
                <button type="button" onClick={() => setIsPopupOpen(true)} className="text-[#EF2725] text-xs">
                  Lupa Password?
                </button>
              </div>
            </label>
            <button className={`py-3 rounded-full mt-3 ${isFormValid ? "bg-[#EF2725] text-white" : "bg-[#F3F4F8] text-[#BFBFBF]"}`} disabled={!isFormValid || isLoading}>
              {isLoading ? "Loading..." : "Masuk"}
            </button>

            <div className="text-sm text-center text-gray-700">
              Anda belum punya akun?{" "}
              <Link to="/register" className="text-[#EF2725] font-bold">
                Daftar
              </Link>
            </div>
            <div className="my-3 flex justify-center items-center gap-3">
              <div className=" w-full h-[1px] bg-[#DDE5E9]"></div>
              <p className="text-sm text-[#D9D9D9] font-bold ">Atau</p>
              <div className=" w-full h-[1px] bg-[#DDE5E9]"></div>
            </div>
            <button className="py-2  rounded-full border border-[#DDE5E9]  mt-3  text-[#4A4D4F]">
              <Link className=" flex justify-center items-center gap-3" to="https://id-id.facebook.com/login.php/">
                <img src={Google} alt="" /> Masuk dengan Google
              </Link>
            </button>
          </form>
        </div>
      </div>

      {isPopupOpen && <PopupLupaPassword onClose={() => setIsPopupOpen(false)} />}

      {isOtpPopupOpen && userId && (
        <OtpPopup
          onClose={() => setIsOtpPopupOpen(false)}
          onSubmit={(otp) => {
            /* Handle OTP submission */
          }}
          userId={userId}
          email={email} // Pass email as prop
          password={password} // Pass password as prop
          legalitas={legalitas} // Pass legalitas as prop
        />
      )}
    </section>
  );
};

export default Login;
