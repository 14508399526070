import React, { useState, useEffect } from "react";
import { IconChervonColor, IconExportRed, IconChevronButton, IconChevronTop } from "../icons";
import { IconChervon } from "../../../../transaksi/Icon/icons";
import ExportSaldo from "../exportSaldo";
import { IconCLose } from "../../../../../assets/icon";
import axios from "axios";
import "../icons/style.css";
import { exportToCSV, exportToExcel, printData, exportToPDF } from "./export"; // Import fungsi dari file utilitas

const months = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface DetailSaldoProps {
  onClose: () => void;
  projectId: string | null;
}

const DetailSaldo: React.FC<DetailSaldoProps> = ({ onClose, projectId }) => {
  const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
  const [activeMonth, setActiveMonth] = useState<string | null>(null);
  const [riwayat, setRiwayat] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<typeof riwayat>(riwayat);
  const [showExport, setShowExport] = useState(false);
  const token = localStorage.getItem("token");

  const handleMonthChange = (direction: "next" | "prev") => {
    if (direction === "next") {
      setCurrentMonthIndex((prevIndex) => Math.min(prevIndex + 1, months.length - 6));
    } else {
      setCurrentMonthIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  const handleMonthClick = (month: string) => {
    setActiveMonth(month);
    const monthIndex = months.indexOf(month);
    const dataForMonth = riwayat.filter((item) => {
      const transactionMonth = new Date(item.createdAt).getMonth();
      return transactionMonth === monthIndex;
    });
    setFilteredData(dataForMonth);
  };

  const handleShowAll = () => {
    setActiveMonth(null);
    setFilteredData(riwayat);
  };

  const displayedMonths = months.slice(currentMonthIndex, currentMonthIndex + 6);

  const handleExport = () => {
    setShowExport(!showExport);
  };

  const handleClose = () => {
    setShowExport(false);
  };

  useEffect(() => {
    if (projectId) {
      const fetchTransactions = () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/sandbox/transaction-history-list/${projectId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setRiwayat(response.data.mutasi);
            setFilteredData(response.data.mutasi);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      };

      fetchTransactions();
    }
  }, [projectId, token]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[473px] rounded-3xl px-8 pb-10 pt-5 relative ms-64">
        <button onClick={onClose} className="flex justify-end w-full mb-[15px]">
          {IconCLose("24", "24")}
        </button>
        <div className="flex justify-between">
          <p className="text-[14px] font-[Plus Jakarta Sans] font-semibold text-[#25282B]">Detail Mutasi</p>
          <div onClick={handleExport} className="flex items-center gap-[10px] border border-[#E7E7F0] rounded-full py-[8px] px-[19px] cursor-pointer">
            <IconExportRed />
            <p className="mt-[-5px] text-red-500 font-semibold">Export</p>
            <div className="mt-[2px]">{IconChervonColor("red")}</div>
          </div>
        </div>
        <div className="absolute right-[32px] mt-[10px] z-40">
          {showExport && (
            <ExportSaldo
              onClose={handleClose}
              onExportCSV={() => exportToCSV(filteredData)} // Panggil fungsi CSV
              onExportExcel={() => exportToExcel(filteredData)} // Panggil fungsi Excel
              onPrint={() => printData(filteredData)} // Panggil fungsi Print
              onExsportPDF={() => exportToPDF(filteredData)} // Panggil fungsi PDF
            />
          )}
        </div>
        <div className="flex gap-2 mt-4 items-center border-b pb-[10px] border-slate-100">
          <button onClick={() => handleMonthChange("prev")} className="rotate-90 outline-none">
            <IconChervon />
          </button>
          <button onClick={handleShowAll} className={`ml-[10px] grid outline-none grid-cols-6 items-center justify-center text-center ${activeMonth === null ? "text-orange-600" : ""}`}>
            All
          </button>
          {displayedMonths.map((month) => (
            <button key={month} onClick={() => handleMonthClick(month)} className={`ml-[10px] outline-none grid grid-cols-6 items-center justify-center text-center ${month === activeMonth ? "text-orange-600" : ""}`}>
              {month}
            </button>
          ))}
          <button onClick={() => handleMonthChange("next")} className="transform -rotate-90 outline-none">
            <IconChervon />
          </button>
        </div>
        <div className="modal-preview-product__container mt-4 h-[300px] pe-2">
          {filteredData.length > 0 ? (
            filteredData.map((data, index) => (
              <div key={index} className="flex justify-between mb-3">
                <div className="flex gap-[15px] text-left">
                  <div className="">{data.keterangan === "kredit" ? <IconChevronButton /> : <IconChevronTop />}</div>
                  <div>
                    <p className="text-[#818284] text-[14px]">{data.tujuan_dana.length > 10 ? "**********" + data.tujuan_dana.slice(-6) : data.tujuan_dana}</p>
                    <p className="text-[#4A4D4F] text-[12px]">
                      {new Date(data.createdAt).toLocaleDateString("id-ID", {
                        weekday: "long",
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </div>
                <p className="text-[#222831] font-medium ">Rp. {parseFloat(data.gross_amount).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}</p>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">Data tidak tersedia</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailSaldo;
