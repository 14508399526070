import React, { useState, useEffect } from "react";
import { cloudfile, pdficon, imagered } from "../../../assets/icon";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Define the types for each region
interface Province {
  id: string;
  name: string;
}

interface Kabupaten {
  id: string;
  name: string;
}

interface Kecamatan {
  id: string;
  name: string;
}

interface Kelurahan {
  id: string;
  name: string;
}

const DataQris: React.FC = () => {
  // DATA PRIBADI
  const [nama_individu, setnama_individu] = useState("");
  const [nomor_ktp, setnomor_ktp] = useState("");
  const [email, setemail] = useState("");
  const [no_telp, setno_telp] = useState("");
  const [tmpt_lahir, settmpt_lahir] = useState("");
  const [tgl_lahir, settgl_lahir] = useState("");
  const [nama_ibu, setnama_ibu] = useState("");
  const [alamat, setalamat] = useState("");
  const [provinces, setProvinces] = useState({ id: "", name: "" });
  const [kabupaten, setKabupaten] = useState({ id: "", name: "" });
  const [kecamatan, setKecamatan] = useState({ id: "", name: "" });
  const [kelurahan, setKelurahan] = useState({ id: "", name: "" });

  // DATA TOKO
  const [nama_toko, setnama_toko] = useState("");
  const [foto_ktp, setfoto_ktp] = useState<string>("");
  const [foto_ktp_selfie, setfoto_ktp_selfie] = useState<File | null>(null);
  const [foto_toko_luar, setfoto_toko_luar] = useState<File | null>(null);
  const [foto_toko_dalam, setfoto_toko_dalam] = useState<File | null>(null);
  const [foto_toko_luar_dgn_pemilik, setfoto_toko_luar_dgn_pemilik] = useState<File | null>(null);
  const [foto_toko_dalam_dgn_pemilik, setfoto_toko_dalam_dgn_pemilik] = useState<File | null>(null);
  const [provinces_toko, setProvinces_toko] = useState({ id: "", name: "" });
  const [kabupaten_toko, setKabupaten_toko] = useState({ id: "", name: "" });
  const [kecamatan_toko, setKecamatan_toko] = useState({ id: "", name: "" });
  const [kelurahan_toko, setKelurahan_toko] = useState({ id: "", name: "" });
  const [alamat_toko, setalamat_toko] = useState("");

  // State for selected values
  const [selectedProvince, setSelectedProvince] = useState<Province[]>([]);
  const [selectedKabupaten, setSelectedKabupaten] = useState<Kabupaten[]>([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState<Kecamatan[]>([]);
  const [selectedKelurahan, setSelectedKelurahan] = useState<Kelurahan[]>([]);
  const [selectedProvinceToko, setSelectedProvinceToko] = useState<Province[]>([]);
  const [selectedKabupatenToko, setSelectedKabupatenToko] = useState<Kabupaten[]>([]);
  const [selectedKecamatanToko, setSelectedKecamatanToko] = useState<Kecamatan[]>([]);
  const [selectedKelurahanToko, setSelectedKelurahanToko] = useState<Kelurahan[]>([]);

  // UPLOAD PROGRES

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);

  const [uploadProgressToko, setUploadProgressToko] = useState<number>(0);
  const [isUploadingToko, setIsUploadingToko] = useState(false);

  const [uploadProgressTokoDalam, setUploadProgressTokoDalam] = useState<number>(0);
  const [isUploadingTokoDalam, setIsUploadingTokoDalam] = useState(false);

  const [uploadProgressTokoLuarPemilik, setUploadProgressTokoLuarPemilik] = useState<number>(0);
  const [isUploadingTokoLuarPemilik, setIsUploadingTokoLuarPemilik] = useState(false);

  const [uploadProgressTokoDalamPemilik, setUploadProgressTokoDalamPemilik] = useState<number>(0);
  const [isUploadingTokoDalamPemilik, setIsUploadingTokoDalamPemilik] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const isFormValid = () =>
    nama_individu &&
    nomor_ktp &&
    email &&
    no_telp &&
    nama_ibu &&
    tgl_lahir &&
    tmpt_lahir &&
    alamat &&
    provinces &&
    kabupaten &&
    kecamatan &&
    kelurahan &&
    nama_toko &&
    foto_ktp &&
    foto_ktp_selfie &&
    foto_toko_luar &&
    foto_toko_dalam &&
    foto_toko_luar_dgn_pemilik &&
    foto_toko_dalam_dgn_pemilik &&
    provinces_toko &&
    kabupaten_toko &&
    kecamatan_toko &&
    kelurahan_toko &&
    alamat_toko;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}profile-data-individu`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const datas = response.data.data.user_id;
        const data = response.data.data;
        setnama_individu(data.nama_individu || "");
        setnomor_ktp(data.nomor_ktp || "");
        setemail(datas.email || "");
        setno_telp(datas.no_telp || "");
        setalamat(data.alamat || "");

        // Assuming file URLs are returned by the backend:

        setfoto_ktp(data.foto_ktp || "");
      })
      .catch((error) => {
        console.error("Error fetching company profile data:", error);
      });
  }, []);

  // Fetch provinces on component mount
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}provinsi`)
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setSelectedProvince(response.data.data);
        } else {
          console.error("Invalid data format for provinces");
        }
        // const id = response.data.data.id;
      })
      .catch((error) => console.error("Error fetching provinces:", error));
  }, []);

  // Fetch kabupaten when a province is selected
  useEffect(() => {
    if (provinces) {
      // const provinsiTerpilih = selectedProvince.find((p) => p.name === provinces);
      // if (provinsiTerpilih) {
      // }
      axios
        .get(`${process.env.REACT_APP_API_URL}provinsi/${provinces.id}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].regencies)) {
            setSelectedKabupaten(response.data.data[0].regencies); // Ambil regencies langsung dari respons
            setSelectedKecamatan([]); // Clear kecamatan ketika provinsi berubah
            setSelectedKelurahan([]); // Clear kelurahan ketika provinsi berubah
          } else {
            console.error("Invalid data format for kabupaten");
          }
        })
        .catch((error) => console.error("Error fetching kabupaten:", error));
    }
  }, [provinces, selectedProvince]);

  // Fetch kecamatan when a kabupaten is selected
  useEffect(() => {
    if (kabupaten) {
      axios
        .get(`${process.env.REACT_APP_API_URL}regency/${kabupaten.id}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].districts)) {
            setSelectedKecamatan(response.data.data[0].districts);
            setSelectedKelurahan([]); // Clear kelurahan when kabupaten changes
          } else {
            console.error("Invalid data format for kecamatan");
          }
        })
        .catch((error) => console.error("Error fetching kecamatan:", error));
    }
  }, [kabupaten]);

  // Fetch kelurahan when a kecamatan is selected
  useEffect(() => {
    if (kecamatan) {
      axios
        .get(`${process.env.REACT_APP_API_URL}district/${kecamatan.id}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].villages)) {
            setSelectedKelurahan(response.data.data[0].villages);
          } else {
            console.error("Invalid data format for kelurahan");
          }
        })
        .catch((error) => console.error("Error fetching kelurahan:", error));
    }
  }, [kecamatan]);

  // Fetch provinces TOKO
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}provinsi`)
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setSelectedProvinceToko(response.data.data);
        } else {
          console.error("Invalid data format for provinces");
        }
      })
      .catch((error) => console.error("Error fetching provinces:", error));
  }, []);

  // Fetch kabupaten TOKO
  useEffect(() => {
    if (provinces_toko) {
      axios
        .get(`${process.env.REACT_APP_API_URL}provinsi/${provinces_toko.id}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].regencies)) {
            setSelectedKabupatenToko(response.data.data[0].regencies);
            setSelectedKecamatanToko([]); // Clear kecamatan ketika provinsi berubah
            setSelectedKelurahanToko([]); // Clear kelurahan ketika provinsi berubah
          } else {
            console.error("Invalid data format for kabupaten");
          }
        })
        .catch((error) => console.error("Error fetching kabupaten:", error));
    }
  }, [provinces_toko]);

  // Fetch kecamatan TOKO
  useEffect(() => {
    if (kabupaten_toko) {
      axios
        .get(`${process.env.REACT_APP_API_URL}regency/${kabupaten_toko.id}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].districts)) {
            setSelectedKecamatanToko(response.data.data[0].districts);
            setSelectedKelurahanToko([]); // Clear kelurahan when kabupaten changes
          } else {
            console.error("Invalid data format for kecamatan");
          }
        })
        .catch((error) => console.error("Error fetching kecamatan:", error));
    }
  }, [kabupaten_toko]);

  // Fetch kelurahan TOKO
  useEffect(() => {
    if (kecamatan_toko) {
      axios
        .get(`${process.env.REACT_APP_API_URL}district/${kecamatan_toko.id}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].villages)) {
            setSelectedKelurahanToko(response.data.data[0].villages);
          } else {
            console.error("Invalid data format for kelurahan");
          }
        })
        .catch((error) => console.error("Error fetching kelurahan:", error));
    }
  }, [kecamatan_toko]);

  // ANIMASI UPLOAD FILE

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFile(file);
    }
  };

  const uploadFile = (file: File) => {
    setIsUploading(true);
    const fakeUpload = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeToko = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFileToko(file);
    }
  };

  const uploadFileToko = (file: File) => {
    setIsUploadingToko(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressToko((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingToko(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeTokoDalam = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFileTokoDalam(file);
    }
  };

  const uploadFileTokoDalam = (file: File) => {
    setIsUploadingTokoDalam(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressTokoDalam((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingTokoDalam(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeTokoLuarPemilik = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFileTokoLuarPemilik(file);
    }
  };

  const uploadFileTokoLuarPemilik = (file: File) => {
    setIsUploadingTokoLuarPemilik(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressTokoLuarPemilik((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingTokoLuarPemilik(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeTokoDalamPemilik = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFileTokoDalamPemilik(file);
    }
  };

  const uploadFileTokoDalamPemilik = (file: File) => {
    setIsUploadingTokoDalamPemilik(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressTokoDalamPemilik((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingTokoDalamPemilik(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isFormValid()) return;

    const formData = new FormData();
    // DATA PRIBADI
    formData.append("nama_individu", nama_individu);
    formData.append("nomor_ktp", nomor_ktp);
    formData.append("email", email);
    formData.append("no_telp", no_telp);
    formData.append("tmpt_lahir", tmpt_lahir);
    formData.append("tgl_lahir", tgl_lahir);
    formData.append("nama_ibu", nama_ibu);
    formData.append("alamat", alamat);
    formData.append("provinces", provinces.name);
    formData.append("kabupaten", kabupaten.name);
    formData.append("kecamatan", kecamatan.name);
    formData.append("kelurahan", kelurahan.name);

    // DATA PRIBADI
    formData.append("nama_toko", nama_toko);
    formData.append("foto_ktp", foto_ktp);
    formData.append("foto_ktp_selfie", foto_ktp_selfie as Blob);
    formData.append("foto_toko_luar", foto_toko_luar as Blob);
    formData.append("foto_toko_dalam", foto_toko_dalam as Blob);
    formData.append("foto_toko_luar_dgn_pemilik", foto_toko_luar_dgn_pemilik as Blob);
    formData.append("foto_toko_dalam_dgn_pemilik", foto_toko_dalam_dgn_pemilik as Blob);
    formData.append("provinces_toko", provinces_toko.name);
    formData.append("kabupaten_toko", kabupaten_toko.name);
    formData.append("kecamatan_toko", kecamatan_toko.name);
    formData.append("kelurahan_toko", kelurahan_toko.name);
    formData.append("alamat_toko", alamat_toko);

    // Use URLs directly since we're not allowing file re-upload

    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}create-pengajuan-qris`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data) {
          // alert("berhasil");
          navigate(`/me/proyek-qris-detail`);
        }
      })
      .catch((error) => {
        alert("gagal mengirim data");
        console.error("Gagal mengirim data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const isVerified = localStorage.getItem("is_verified");
    if (isVerified === "true") {
      navigate("");
    }
  }, [navigate]);

  const handleProvinceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const provinceId = selectedOption.value;
    const provinceName = selectedOption.text;

    setProvinces({ id: provinceId, name: provinceName });
  };

  const handleKabupatenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const kabupatenId = selectedOption.value;
    const kabupatenName = selectedOption.text;

    setKabupaten({ id: kabupatenId, name: kabupatenName });
  };

  const handleKecamatanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const kecamatanId = selectedOption.value;
    const kecamatanName = selectedOption.text;

    setKecamatan({ id: kecamatanId, name: kecamatanName });
  };

  const handleKelurahanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const kelurahanId = selectedOption.value;
    const kelurahanName = selectedOption.text;

    setKelurahan({ id: kelurahanId, name: kelurahanName });
  };

  const handleProvinceTokoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const provinceTokoId = selectedOption.value;
    const provinceTokoName = selectedOption.text;

    setProvinces_toko({ id: provinceTokoId, name: provinceTokoName });
  };

  const handleKabupatenTokoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const kabupatenTokoId = selectedOption.value;
    const kabupatenTokoName = selectedOption.text;

    setKabupaten_toko({ id: kabupatenTokoId, name: kabupatenTokoName });
  };

  const handleKecamatanTokoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const kecamatanTokoId = selectedOption.value;
    const kecamatanTokoName = selectedOption.text;

    setKecamatan_toko({ id: kecamatanTokoId, name: kecamatanTokoName });
  };

  const handleKelurahanTokoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const kelurahanTokoId = selectedOption.value;
    const kelurahanTokoName = selectedOption.text;

    setKelurahan_toko({ id: kelurahanTokoId, name: kelurahanTokoName });
  };

  return (
    <section>
      <div className="mb-10 p-[50px] bg-white border border-[#DDE5E9] rounded-2xl">
        <h2 className="text-xl font-bold mb-4 text-start">Data Pribadi</h2>

        {/* Nama Lengkap & ktp */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nama Lengkap</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase cursor-pointer "
              value={nama_individu}
              placeholder="Masukan Nama Lengkap"
              onChange={(e) => setnama_individu(e.target.value)}
              readOnly
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Nomor KTP</label>
            <input type="number" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-pointer " value={nomor_ktp} onChange={(e) => setnomor_ktp(e.target.value.slice(0, 16))} readOnly />
          </div>
        </div>

        {/*Email & Tgl No Telepon*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Email</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize  cursor-pointer"
              value={email}
              placeholder="Masukan ID Outlet"
              onChange={(e) => setemail(e.target.value)}
              readOnly
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">No Telepon</label>
            <input type="text" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-pointer  " value={no_telp} onChange={(e) => setno_telp(e.target.value)} readOnly />
          </div>
        </div>

        {/*Tempat Lahir & Tgl Lahir*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Tempat Lahir</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize capitalize "
              value={tmpt_lahir}
              placeholder="Masukan Tempat Lahir"
              onChange={(e) => settmpt_lahir(e.target.value)}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Tanggal Lahir</label>
            <input type="date" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]  " value={tgl_lahir} onChange={(e) => settgl_lahir(e.target.value)} />
          </div>
        </div>

        {/*No Ibu Kandung */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nama Ibu Kandung</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase "
              value={nama_ibu}
              placeholder="Masukan Nama Ibu Kandung"
              onChange={(e) => setnama_ibu(e.target.value)}
            />
          </div>
        </div>

        {/* Daerah  */}
        {/* Provinces & kab*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Provinsi</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={provinces.id} onChange={handleProvinceChange}>
              <option value="">Pilih Provinsi</option>
              {selectedProvince.map((province) => (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kabupaten */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kabupaten</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={kabupaten.id} onChange={handleKabupatenChange} disabled={!provinces.id}>
              <option value="">Pilih Kabupaten</option>
              {selectedKabupaten.map((kab) => (
                <option key={kab.id} value={kab.id}>
                  {kab?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Kecamatan */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kecamatan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={kecamatan.id} onChange={handleKecamatanChange} disabled={!kabupaten.id}>
              <option value="">Pilih Kecamatan</option>
              {selectedKecamatan.map((kec) => (
                <option key={kec.id} value={kec.id}>
                  {kec.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kelurahan */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kelurahan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={kelurahan.id} onChange={handleKelurahanChange} disabled={!kecamatan.id}>
              <option value="">Pilih Kelurahan</option>
              {selectedKelurahan.map((kel) => (
                <option key={kel.id} value={kel.id}>
                  {kel.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Alamat */}
        <div className="mb-4 w-full">
          <label className="block text-gray-700 text-start">Alamat</label>
          <textarea className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-pointer" onChange={(e) => setalamat(e.target.value)} value={alamat} readOnly></textarea>
        </div>

        {/* ============================================ DATA TOKO ================================================== */}

        <h1 className="font-bold my-9 text-xl text-start">Data Toko</h1>

        {/* Nama Toko*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nama Toko</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase "
              value={nama_toko}
              placeholder="Masukan Nama Lengkap"
              onChange={(e) => setnama_toko(e.target.value)}
            />
          </div>
        </div>

        {/* UNGGAH KTP TOKO  */}
        <div className="mb-7 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">File KTP</label>
            <div className="flex items-center justify-between bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl">
              <div className="flex gap-2 items-center">
                {pdficon()}
                <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{foto_ktp.split("/").pop()}</p>
              </div>

              <a href={foto_ktp} target="_blank" rel="noopener noreferrer">
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl">Buka File</button>
              </a>
            </div>
          </div>

          {/* UNGGAH FOTO SELFIE */}
          <div className="w-full">
            <label className="block text-gray-700">Unggah foto selfie dengan KTP</label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgress}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_ktp_selfie(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => handleFileChange(e, setfoto_ktp_selfie)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah Gambar, Max 10 MB</p>
            {foto_ktp_selfie && !isUploading && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {imagered()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{foto_ktp_selfie.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_ktp_selfie(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* FOTO LOKASI TOKO LUAR & DALAM */}
        <div className="mb-7 flex gap-10 justify-between text-start">
          {/* LUAR */}
          <div className="w-full">
            <label className="block text-gray-700">Unggah Foto Toko ( Dari Luar ) </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingToko ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressToko}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_toko_luar(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10  ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => handleFileChangeToko(e, setfoto_toko_luar)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah Gambar, Max 10 MB</p>
            {foto_toko_luar && !isUploadingToko && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white  p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {imagered()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{foto_toko_luar.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_toko_luar(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>

          {/* DALAM */}
          <div className="w-full">
            <label className="block text-gray-700">Unggah Foto Toko ( Dari Dalam ) </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingTokoDalam ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressTokoDalam}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_toko_dalam(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10  ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => handleFileChangeTokoDalam(e, setfoto_toko_dalam)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah Gambar, Max 10 MB</p>
            {foto_toko_dalam && !isUploadingTokoDalam && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white  p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {imagered()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{foto_toko_dalam.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_toko_dalam(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* FOTO LOKASI TOKO LUAR DENGAN PEMILIK & DALAM DENGAN PEMILIK */}
        <div className="mb-8 flex gap-10 justify-between text-start">
          {/* LUAR DGN PEMILIK */}
          <div className="w-full">
            <label className="block text-gray-700 lg:text-[15px] xl:text-base">Unggah Foto Toko ( Dari Luar Dengan Pemilik ) </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingTokoLuarPemilik ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressTokoLuarPemilik}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_toko_luar_dgn_pemilik(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10  ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => handleFileChangeTokoLuarPemilik(e, setfoto_toko_luar_dgn_pemilik)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah Gambar, Max 10 MB</p>
            {foto_toko_luar_dgn_pemilik && !isUploadingTokoLuarPemilik && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white  p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {imagered()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{foto_toko_luar_dgn_pemilik.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_toko_luar_dgn_pemilik(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>

          {/* DALAM DGN PEMILIK */}
          <div className="w-full">
            <label className="block text-gray-700 lg:text-[14.5px] xl:text-base">Unggah Foto Toko ( Dari Dalam Dengan Pemilik ) </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingTokoDalamPemilik ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressTokoDalamPemilik}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_toko_dalam_dgn_pemilik(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10  ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => handleFileChangeTokoDalamPemilik(e, setfoto_toko_dalam_dgn_pemilik)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah Gambar, Max 10 MB</p>
            {foto_toko_dalam_dgn_pemilik && !isUploadingTokoDalamPemilik && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white  p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {imagered()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{foto_toko_dalam_dgn_pemilik.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_toko_dalam_dgn_pemilik(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* DAERAH INPUT */}
        {/* Provinces & kab*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Provinsi</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={provinces_toko.id} onChange={handleProvinceTokoChange}>
              <option value="">Pilih Provinsi</option>
              {selectedProvinceToko.map((province) => (
                <option key={province.id} value={province.id}>
                  {province?.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kabupaten */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kabupaten</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={kabupaten_toko.id} onChange={handleKabupatenTokoChange} disabled={!provinces_toko.id}>
              <option value="">Pilih Kabupaten</option>
              {selectedKabupatenToko.map((kab) => (
                <option key={kab.id} value={kab.id}>
                  {kab?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Kecamatan */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kecamatan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={kecamatan_toko.id} onChange={handleKecamatanTokoChange} disabled={!kabupaten_toko.id}>
              <option value="">Pilih Kecamatan</option>
              {selectedKecamatanToko.map((kec) => (
                <option key={kec.id} value={kec.id}>
                  {kec.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kelurahan */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kelurahan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={kelurahan_toko.id} onChange={handleKelurahanTokoChange} disabled={!kecamatan_toko.id}>
              <option value="">Pilih Kelurahan</option>
              {selectedKelurahanToko.map((kel) => (
                <option key={kel.id} value={kel.id}>
                  {kel.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Alamat Toko */}
        <div className="mb-4 w-full">
          <label className="block text-gray-700 text-start">Alamat Toko</label>
          <textarea className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] " onChange={(e) => setalamat_toko(e.target.value)} value={alamat_toko}></textarea>
        </div>

        <button
          onClick={handleVerify}
          className={`flex justify-start mt-10 py-2 px-14 bg-orange-600 text-white rounded-full font-semibold ${!isFormValid() && "bg-slate-200 cursor-not-allowed"}`}
          disabled={!isFormValid() || isLoading} // Disable the button while loading
        >
          {isLoading ? "Loading ....." : "Lanjut Verifikasi"}
        </button>
      </div>
    </section>
  );
};

export default DataQris;
