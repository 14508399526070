import React, { useRef, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "../style.css";
import { IconChevron, IconClose, editorange } from "../icon";
import { RootState } from "../../../features/stores";
import { useSelector } from "react-redux";
import { ProjectData } from "../../../features/interface/proyek.interface";

interface PaymentChannel {
  _id: string;
  namaBank: string;
  biayaLayanan: number;
  logoBank: string;
  is_aktif: boolean;
}

interface PaymentData {
  dataAksesPembayaran: {
    _id: string;
    // idPaymentChannel: {
    //   _id: string;
    //   namaBank: string;
    //   biayaLayanan: number;
    //   logoBank: string;
    //   is_aktif: boolean;
    // };
    idPaymentChannel: PaymentChannel;
    isActive: boolean;
    status: string;
    description: string;
    idPaymentFee: {
      _id: string;
      feeType: string;
      status: string;
    };
  }[];
  dataOpsiPayment: {
    _id: string;
    feeType: string;
    status: string;
  }[];
}

// Define the type for the selected payment data

// interface Payment {
//   id: string;
//   idPaymentChannel: {
//     is_aktif: boolean;
//     biayaLayanan: number;
//     namaBank: string;
//   };
// }

// interface Props {
//   payments: Payment[];
// }

const Bank = () => {
  const { id } = useParams();
  const [paymentData, setPaymentData] = useState<PaymentData | null>(null);
  const [selectedFee, setSelectedFee] = useState<{ [key: string]: { _id: string; feeType: string; status: string } | null }>({});
  const [isActive, setIsActive] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState<{ [key: string]: boolean }>({});
  const [showPopupError, setShowPopupError] = useState<{ [key: string]: boolean }>({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { projectData, selectedProjectId } = useSelector((state: RootState) => state.project);
  const [project, setProject] = useState<ProjectData | null>(null);
  // const [isPopupOpenEdit, setIsPopupOpenEdit] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null); // Store the index of the clicked item
  const [biayaLayanan, setBiayaLayanan] = useState<number | null>(null);
  const [isValid, setIsValid] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (selectedProjectId) {
      const selectedProject = projectData?.find((proj) => proj._id === selectedProjectId);
      if (selectedProject) {
        setProject(selectedProject);
      }
    }
  }, [selectedProjectId, projectData]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}get-akses-pembayaran/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const paymentResponse = response.data.data;
          console.log(paymentResponse);

          // Initialize selectedFee with data from API
          const initializedSelectedFee: { [key: string]: { _id: string; feeType: string; status: string } | null } = {};
          paymentResponse.dataAksesPembayaran.forEach((payment: any) => {
            initializedSelectedFee[payment._id] = payment.idPaymentFee || null;
          });

          setPaymentData(paymentResponse);
          setIsActive((prev) => {
            const updatedIsActive = { ...prev };
            paymentResponse.dataAksesPembayaran.forEach((payment: any) => {
              if (!updatedIsActive.hasOwnProperty(payment._id)) {
                updatedIsActive[payment._id] = payment.isActive;
              }
            });
            return updatedIsActive;
          });
          setSelectedFee(initializedSelectedFee);
        })
        .catch((error) => {
          console.error("Error fetching payment data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, token]);

  const handleToggleChange = (id: string) => {
    const newStatus = !isActive[id];
    const updatedIsActive = { ...isActive, [id]: newStatus };

    // Simpan status terbaru ke localStorage
    localStorage.setItem("isActive", JSON.stringify(updatedIsActive));

    setIsActive(updatedIsActive);

    // Jika selectedFee[id] tidak null, ambil _id dari objek tersebut
    const selectedFeeId = selectedFee[id]?._id || null;
    updateStatus(id, newStatus, selectedFeeId);
  };

  const handleFeeChange = (id: string, feeOption: { _id: string; feeType: string; status: string }) => {
    const updatedSelectedFee = { ...selectedFee, [id]: feeOption }; // feeOption adalah objek, bukan hanya string

    setSelectedFee(updatedSelectedFee); // Perbarui state dengan objek feeOption
    updateStatus(id, isActive[id], feeOption._id); // Mengirim hanya _id ke updateStatus
  };

  useEffect(() => {
    // Ambil state dari localStorage jika ada
    const savedIsActive = localStorage.getItem("isActive");
    const savedSelectedFee = localStorage.getItem("selectedFee");

    if (savedIsActive) {
      setIsActive(JSON.parse(savedIsActive));
    }
    if (savedSelectedFee) {
      setSelectedFee(JSON.parse(savedSelectedFee));
    }
  }, []);

  const updateStatus = (id: string, status: boolean, feeId: string | null) => {
    setIsLoading(true);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}update-akses-pembayaran/${id}`,
        {
          isActive: status,
          idPaymentFee: feeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setShowPopup((prev) => ({ ...prev, [id]: true }));
          setTimeout(() => {
            setShowPopup((prev) => ({ ...prev, [id]: false }));
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        setShowPopupError((prev) => ({ ...prev, [id]: true }));
        setTimeout(() => {
          setShowPopupError((prev) => ({ ...prev, [id]: false }));
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Toggle popup for a specific index
  const togglePopupEdit = (index: number, currentBiayaLayanan: number) => {
    setActiveIndex(index);
    setBiayaLayanan(currentBiayaLayanan);
  };

  // Handle input change for biayaLayanan
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    setBiayaLayanan(value);

    // Validasi jika nilai berada di antara 500 dan 6500
    if (value >= 500 && value <= 6500) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleSave = () => {
    const biayaLayananValid = biayaLayanan ?? 0;

    // Check if paymentData is defined and has the required structure
    if (!paymentData || !paymentData.dataAksesPembayaran || paymentData.dataAksesPembayaran.length === 0) {
      console.error("Payment data is not available or invalid.");
      return; // Exit the function early if paymentData is invalid
    }

    if (biayaLayananValid >= 500 && biayaLayananValid <= 6500) {
      if (activeIndex === null) {
        console.error("No payment channel selected.");
        return; // Exit the function early if no payment channel is selected
      }

      // Extracting idPaymentChannel from the selected payment method
      const selectedPayment = paymentData.dataAksesPembayaran[activeIndex];
      const idPaymentChannel = selectedPayment.idPaymentChannel._id;
      const projectId = id; // Use your actual project ID here

      axios
        .put(`https://backend-udin.superdigitalapps.my.id/api/v1.0/update-biaya-layanan/${idPaymentChannel}`, {
          id_project: projectId,
          biayaLayanan: biayaLayananValid,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error("Gagal mengupdate data:", error);
        });
    } else {
      console.error("Biaya layanan tidak valid.");
    }
  };

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        // Close the popup
        setActiveIndex(-1); // or whatever method you use to close the popup
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setActiveIndex]);

  if (!paymentData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Link to="/me/proyek">
        <div className="text-start mb-8 flex gap-2 items-center text-xl font-medium">{IconChevron()} Kembali</div>
      </Link>
      <div className="flex flex-wrap gap-8 mb-5">
        {paymentData.dataAksesPembayaran.map((payment, index) => (
          <div key={payment._id} className={`relative flex flex-col gap-4 border border-gray-300 p-5 rounded-xl w-[280px] content-start ${index === 0 || index === 3 ? "" : ""}`}>
            <div className={`relative flex flex-col gap-4 ${(index === 0 || index === 3) && payment.status !== "Disetujui" ? "blur-sm cursor-not-allowed pointer-events-none" : ""}`}>
              {" "}
              {/* Apply blur only to content except the button */}
              <div className={`flex justify-between items-center ${payment.idPaymentChannel.is_aktif === false ? "blur-sm " : ""}`}>
                <img src={payment.idPaymentChannel?.logoBank} alt={payment.idPaymentChannel?.namaBank} className="h-10 w-auto" />
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={(isActive[payment._id] && payment.idPaymentChannel.is_aktif === true) || false}
                    onChange={() => {
                      if (payment.idPaymentChannel.is_aktif === true) {
                        handleToggleChange(payment._id);
                      }
                    }}
                    className="sr-only peer"
                  />

                  <div className={`w-11 h-6 rounded-full ${isActive[payment._id] && payment.idPaymentChannel.is_aktif === true ? "bg-orange-600" : "bg-gray-300"} peer-focus:outline-none peer-focus:ring-0`}></div>
                  <div className="w-5 h-5 bg-white rounded-full absolute left-[2px] bottom-[2px] peer-checked:translate-x-full transition-transform"></div>
                </label>
              </div>
              <div className="flex justify-between items-center">
                <div className={`text-start text-sm ${payment.idPaymentChannel.is_aktif === false ? "blur-sm" : ""}`}>
                  Biaya Layanan{" "}
                  <span className="font-semibold">
                    {payment.idPaymentChannel?.biayaLayanan.toLocaleString()}
                    {payment.idPaymentChannel?.namaBank === "QRIS" && " " && "%"}
                  </span>{" "}
                  Akan dibebankan ke:
                </div>
                <div className="cursor-pointer" onClick={() => togglePopupEdit(index, payment.idPaymentChannel?.biayaLayanan)}>
                  {editorange()} {/* Your edit icon */}
                </div>
              </div>
              <div className="flex gap-3">
                {payment.idPaymentChannel?.namaBank === "QRIS" ? (
                  // Jika bank adalah QRIS, hanya render merchant label
                  <div className="flex items-center gap-2">
                    <span>Merchant</span>
                  </div>
                ) : (
                  // Jika bukan QRIS, lakukan mapping opsi pembayaran
                  paymentData?.dataOpsiPayment.map((feeOption) => {
                    const isSelectedFee = selectedFee[payment._id]?._id === feeOption._id;

                    // Tentukan jika feeOption dengan feeType "Pelanggan" akan dijadikan default jika tidak ada fee yang dipilih
                    const isDefaultCustomerFee = !selectedFee[payment._id] && feeOption.feeType === "Pelanggan";

                    return (
                      <label key={feeOption._id} className={`flex items-center gap-2 ${payment.idPaymentChannel.is_aktif === false ? " hidden" : ""}`}>
                        <input
                          type="radio"
                          value={feeOption._id}
                          // Memeriksa apakah ini fee yang dipilih atau fee "Pelanggan" sebagai default
                          checked={isSelectedFee || isDefaultCustomerFee}
                          onChange={() => handleFeeChange(payment._id, feeOption)}
                          disabled={!isActive[payment._id] || isLoading}
                          className={`h-5 w-5 cursor-pointer 
                          ${isSelectedFee || isDefaultCustomerFee || payment.idPaymentChannel.is_aktif === true ? "accent-orange-600 border-orange-600" : "accent-transparent bg-gray-200 border-gray-300"} 
                          rounded-full border-2`}
                        />
                        {feeOption.feeType}
                      </label>
                    );
                  })
                )}
              </div>
              {/* Popup kecil yang muncul selama 2 detik */}
              {showPopup[payment._id] && <div className="absolute top-[50px] ms-10 bg-[#000000cb] text-white border border-black s p-3 rounded-lg shadow-md">Berhasil diperbarui!</div>}
              {showPopupError[payment._id] && <div className="absolute top-[50px] ms-10 bg-[#ed000083] text-white border border-black s p-3 rounded-lg shadow-md">Gagal Memperbarui</div>}
            </div>

            {index === 0 || index === 3 || payment.idPaymentChannel?.namaBank === "BCA" || payment.idPaymentChannel?.namaBank === "QRIS" ? (
              payment.status === "Belum disetujui" || payment.status === "Diproses" ? (
                <>
                  <div className="bg-[#FDE6DE] py-2 px-3 text-[#F15A24] border border-[#F15A24] rounded-lg absolute right-4 top-5 cursor-not-allowed ms-5">
                    <p>Dokumen Anda Sedang Dalam Proses Tinjauan Oleh Tim Kami</p>
                  </div>
                  <button className="bg-orange-600 py-2 px-8 text-white rounded-full absolute bottom-4 ms-10">
                    <Link to={`/me/proyek/pembayaran/detail/${project?._id}`}>Cek Sekarang</Link>
                  </button>
                </>
              ) : payment.status === "Ditolak" ? (
                <>
                  <div className="bg-[#FDE6DE] py-2 px-3 text-[#F15A24] border border-[#F15A24] rounded-lg absolute top-14 ">
                    <p className="font-semibold">Data Anda Ditolak</p>

                    <div className="relative w-[218px] cursor-pointer" onClick={() => setIsPopupOpen(true)}>
                      <p className="truncate" style={{ overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical" }}>
                        {payment.description}
                        <span>...</span>
                      </p>
                    </div>

                    {/* Full description popup */}
                    {isPopupOpen && (
                      <div className="absolute -top-[220px] -right-[850px] w-[1500px] h-screen bg-black bg-opacity-50  flex justify-center items-center z-20">
                        <div className="bg-[#FDE6DE] px-9 py-6 rounded-xl ms-48 max-w-[400px] border border-orange-600">
                          <div onClick={() => setIsPopupOpen(false)} className="flex justify-end text-white -mt-2 -me-4 text-xl cursor-pointer ">
                            {IconClose()}
                          </div>
                          <p className="text-[#F15A24] font-semibold">Alasan Penolakan</p>
                          <p className="mt-3 mb-5">{payment.description}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {payment.status !== "Disetujui" && (
                    <button className={`${payment.idPaymentChannel.is_aktif === false ? "blur-sm pointer-events-none absolute bottom-4 right-12" : "bg-orange-600 px-4 py-2 text-white rounded-full absolute bottom-4 right-12"}`}>
                      <Link to={payment.idPaymentChannel.namaBank === "QRIS" ? `${payment._id}/qris` : `${payment._id}`}>Ajukan Penggunaan</Link>
                    </button>
                  )}
                </>
              )
            ) : null}

            {payment.idPaymentChannel.is_aktif === false && (
              <div className="bg-[#FDE6DE] py-2 px-3 text-[#F15A24] border border-[#F15A24] rounded-lg absolute right-4 top-9 cursor-not-allowed ms-5">
                <p>Bank Sedang dalam gangguan, tunggu beberapa saat agar bisa di gunakan kembali</p>
              </div>
            )}

            {/* Popup for editing the fee */}
            {activeIndex === index && (
              <div className="popup-overlay">
                <div ref={popupRef} className="popup-content">
                  <div className="px-5 py-3">
                    <div className="text-orange-600 bg-[#FDE6DE] text-start rounded-lg ps-3 py-2 mb-3">
                      Minimal Biaya Layanan Rp. 500
                      <br />
                      Maksimal Biaya Layanan Rp. 6.500
                    </div>
                    <div>
                      <label htmlFor="">
                        <p className="text-start mb-2">Masukkan Biaya Layanan</p>
                      </label>
                      <input type="number" value={biayaLayanan ?? ""} placeholder="Contoh: Rp. 500" onChange={handleInputChange} className="w-full border rounded-lg p-3" />
                    </div>
                    <button
                      onClick={handleSave}
                      className={`w-full p-3 rounded-full mt-3 ${biayaLayanan && biayaLayanan >= 500 && biayaLayanan <= 6500 ? "bg-orange-600 text-white" : "bg-gray-300 text-gray-200 cursor-not-allowed"}`}
                      disabled={!isValid}>
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bank;
