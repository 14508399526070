import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RootState } from "../../features/stores";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);
const DonutChart: React.FC = () => {
  const { data, status } = useSelector((state: RootState) => state.transactions);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error</div>;
  }

  const persentaseTransaksiPerBank = data?.persentaseTransaksiPerBank || [];

  // const persentaseTransaksiPerBank = [
  //   { bank_name: "Bank BRI", percentage: 25 },
  //   { bank_name: "Bank Mandiri", percentage: 30 },
  //   { bank_name: "Bank BNI", percentage: 20 },
  //   { bank_name: "Bank BCA", percentage: 25 },
  // ];

  const hasData = persentaseTransaksiPerBank.some((item) => item.percentage > 0);

  const getBankColor = (bankName: string) => {
    switch (bankName) {
      case "Bank BRI":
        return "#1A1A1A";
      case "Bank Mandiri":
        return "#F15A24";
      case "Bank BNI":
        return "#F8931F";
      case "Bank BCA":
        return "#555770";
      default:
        return "#000000";
    }
  };

  // Chart data
  const chartData = {
    labels: persentaseTransaksiPerBank.map((item) => item.bank_name),
    datasets: [
      {
        label: "Transaksi Bank",
        data: persentaseTransaksiPerBank.map((item) => item.percentage),
        backgroundColor: persentaseTransaksiPerBank.map((item) => getBankColor(item.bank_name)),
        hoverOffset: 0,
        borderWidth: 0,
      },
    ],
  };

  // Chart options
  const options = {
    cutout: "65%",
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return ` ${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const innerOptions = {
    cutout: "95%",
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return ` ${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="">
      {hasData ? (
        <div>
          {/* ini lebih besar  */}

          {/* ini lebih kecil didalam data diatas */}

          <div className="w-[180px] h-[180px] absolute lg:mt-[60px] lg:ml-[60px]">
            <Doughnut data={chartData} options={innerOptions} />
          </div>

          <Doughnut data={chartData} options={options} className="relative" />
          <div className=" inset-0 flex items-center justify-center">
            <p className="text-[40px] font-bold  text-[#1A1A1A] lg:mt-[-300px]">100%</p>
          </div>
        </div>
      ) : (
        <div className=" h-[280px] w-[280px] border-[50px] border-[#D9D9D9] rounded-full flex items-center justify-center">
          <p className="text-[40px] font-bold text-gray-400">0%</p>
        </div>
      )}
    </div>
  );
};

export default DonutChart;
