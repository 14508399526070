import React, { useEffect, useState } from "react";
import axios from "axios";
import { IconChevronButton, IconChevronTop } from "../../../saldo-proyek/icons";
import { IconChevron1, IconChevron2 } from "../../../../icon";
import { IconExport } from "../Icon/icons";
import ExportSaldo from "./popupExport";
import { exportToCSV, exportToExcel, printData, exportToPDF } from "./export";

interface TransaksiProyekProps {
  projectId: string | null;
}

const TransactionProyek: React.FC<TransaksiProyekProps> = ({ projectId }) => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  // State untuk filter dan pagination
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>("Jenis Pembayaran");
  const [selectedKeterangan, setSelectedKeterangan] = useState<string | null>("Keterangan");
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(8); // Jumlah baris per halaman
  const [visiblePages, setVisiblePages] = useState([1, 2, 3, 4, 5]); // Halaman yang terlihat
  const [showExport, setShowExport] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);

  useEffect(() => {
    if (projectId) {
      const fetchTransactions = () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/sandbox/transaction-history-list/${projectId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setTransactions(response.data.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      };

      fetchTransactions();
    }
  }, [projectId, token]);

  // Handler untuk filter
  const handlePaymentMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const handleKeteranganChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedKeterangan(e.target.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  // Filter data transaksi
  const filteredTransactions = transactions.filter((item) => {
    const paymentMatch = selectedPaymentMethod === "Jenis Pembayaran" || item.order_detail?.bank_name === selectedPaymentMethod;
    const keteranganMatch = selectedKeterangan === "Keterangan" || item.keterangan === selectedKeterangan;
    const dateMatch = selectedDate ? new Date(item.createdAt).toLocaleDateString("id-ID") === new Date(selectedDate).toLocaleDateString("id-ID") : true;

    return paymentMatch && keteranganMatch && dateMatch;
  });

  // Paginasi
  const totalPages = Math.ceil(filteredTransactions.length / rowsPerPage);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredTransactions.slice(indexOfFirstRow, indexOfLastRow);

  useEffect(() => {
    if (totalPages <= 4) {
      setVisiblePages([...Array(totalPages).keys()].map((page) => page + 1));
    } else {
      setVisiblePages([1, 2, 3, 4, 5]);
    }
  }, [totalPages]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);

    if (totalPages <= 4) {
      setVisiblePages([...Array(totalPages).keys()].map((page) => page + 1)); // Only show up to 4 pages
    } else if (pageNumber > 3 && pageNumber <= totalPages - 2) {
      setVisiblePages([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    } else if (pageNumber <= 3) {
      setVisiblePages([1, 2, 3, 4, 5]);
    } else if (pageNumber > totalPages - 2) {
      setVisiblePages([totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleExport = () => {
    setShowExport(!showExport);
  };

  const handleOpenDetail = (transaction: any) => {
    setSelectedTransaction(transaction);
    setShowDetailPopup(true);
  };

  const handleCloseDetail = () => {
    setShowDetailPopup(false);
  };

  return (
    <div>
      <div className="border border-[#DDE5E9] rounded-xl mb-5 pt-2">
        {/* Filter */}
        <div className="flex justify-between m-4">
          <div className="flex justify-flex gap-5">
            <div className="border border-[#DDE5E9] rounded-lg p-2">
              <select onChange={handlePaymentMethodChange} value={selectedPaymentMethod || ""}>
                <option value="Jenis Pembayaran">Jenis Pembayaran</option>
                <option value="Bank BCA">Bank BCA</option>
                <option value="Bank BNI">Bank BNI</option>
                <option value="Bank BRI">Bank BRI</option>
                <option value="Bank Mandiri">Bank Mandiri</option>
              </select>
            </div>

            <div className="border border-[#DDE5E9] rounded-lg p-2">
              <select onChange={handleKeteranganChange} value={selectedKeterangan || ""}>
                <option value="Keterangan">Keterangan</option>
                <option value="kredit">Kredit</option>
                <option value="debit">Debit</option>
              </select>
            </div>
          </div>

          <div className="flex gap-3">
            <div className="border border-[#DDE5E9] rounded-lg p-2">
              <input type="date" onChange={handleDateChange} />
            </div>

            <div onClick={handleExport} className="flex items-center border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[10px] cursor-pointer bg-gradient-to-r from-[#EF2725] to-[#F26023] ">
              <IconExport />
              <p className=" text-white font-semibold">Export</p>
            </div>
            <div className="absolute right-[35px] mt-[50px] z-40">
              {showExport && (
                <ExportSaldo
                  onExportCSV={() => exportToCSV(filteredTransactions)} // Panggil fungsi CSV
                  onExportExcel={() => exportToExcel(filteredTransactions)} // Panggil fungsi Excel
                  onPrint={() => printData(filteredTransactions)} // Panggil fungsi Print
                  onExsportPDF={() => exportToPDF(filteredTransactions)} // Panggil fungsi PDF
                />
              )}
            </div>
          </div>
        </div>

        {/* Tabel transaksi */}
        <div className="overflow-x-auto ">
          <table className="table-auto w-full">
            <thead>
              <tr className="text-[#85898C] font-medium border-b border-t text-[14px]">
                <th className="p-2"></th>
                <th className="p-2 w-[150px]">ID Transaksi</th>
                <th className="p-2">Tanggal Transaksi</th>
                <th className="p-2">Jumlah</th>
                <th className="p-2">Nomor VA</th>
                <th className="p-2">Jenis Pembayaran</th>
                <th className="p-2 w-[120px]">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={8} className="text-center p-2">
                    Processing...
                  </td>
                </tr>
              ) : currentRows.length > 0 ? (
                currentRows.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="p-2">{item.keterangan === "kredit" ? <IconChevronButton /> : <IconChevronTop />}</td>
                    <td className="p-2">{item.transaction_id}</td>
                    <td className="p-2">
                      {new Date(item.transaction_date)
                        .toLocaleDateString("id-ID", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                        .replace(/\//g, "-")}
                    </td>
                    <td className="p-2">Rp.{parseFloat(item.gross_amount).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}</td>
                    <td className="p-2">{item.tujuan_dana.length > 10 ? "**********" + item.tujuan_dana.slice(-6) : item.tujuan_dana}</td>
                    <td className="p-2">{item.order_detail?.bank_name}</td>
                    <td className="p-2">
                      <button onClick={() => handleOpenDetail(item)}>Detail</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="text-center p-2">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-end items-center mb-8">
        {totalPages > 5 && (
          <button onClick={handlePrevious} disabled={currentPage === 1} className="px-2 ">
            <span className="text-[#1a1a1a] ">{IconChevron1()}</span>
          </button>
        )}

        {visiblePages.map((page) => (
          <button key={page} onClick={() => paginate(page)} className={`mx-1 px-4 py-2 rounded-full border border-[#FF5722] ${page === currentPage ? "bg-[#FF5722] text-white" : "bg-white text-[#FF5722]"}`}>
            {page}
          </button>
        ))}

        {totalPages > 5 && (
          <button onClick={handleNext} disabled={currentPage === totalPages} className="px-2">
            <span className="text-[#1a1a1a]">{IconChevron2()}</span>
          </button>
        )}
      </div>

      {/* {selectedTransaction.transaction_id}
      {new Date(selectedTransaction.transaction_date).toLocaleDateString("id-ID")}
      {selectedTransaction.gross_amount.toLocaleString("id-ID")}
      {selectedTransaction.metode_pembayaran?.namaBank}
      {selectedTransaction.keterangan}
      <button onClick={handleCloseDetail} className="bg-[#FF5722] text-white rounded-lg px-4 py-2">
              Tutup
            </button> */}

      {/* Popup Detail Transaksi */}
      {showDetailPopup && selectedTransaction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-3xl ms-56 p-6 w-[460px] shadow-lg">
            <h1 className="text-[30px] font-medium text-[#222831]">Detail Transaksi</h1>
            <div className=" h-56  relative bg-white rounded-[22px] border border-[#f4f7f8] overflow-hidden mt-6 mb-6">
              {/* Elemen dekoratif melingkar */}
              <div className="w-[377px] h-[377px] left-[-144px] top-[170px] absolute bg-[#1a1a1a]/5 rounded-full border" />
              <div className="w-[377px] h-[377px] right-[-144px] top-[-320px] absolute bg-[#1a1a1a]/5 rounded-full border" />

              {/* Konten utama */}

              <section className="left-[32px] top-[20px] absolute">
                <article className="w-full">
                  {/* Nama Pemilik Rekening */}
                  <div className="text-start flex flex-wrap gap-16">
                    <div className="flex flex-col gap-2">
                      <div className="w-full flex items-start gap-5">
                        <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">No Referensi</p>
                        <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                        <p className="text-[#1a1a1a] font-normal leading-[16.8px]">{selectedTransaction.nomor_referensi}</p>
                      </div>

                      <div className="w-full flex items-start gap-5">
                        <p className="text-[#1a1a1a] font-semibold tracking-tight w-32">Tanggal Transaksi</p>
                        <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                        <p className="text-[#1a1a1a]  font-normal leading-[16.8px]">{new Date(selectedTransaction.transaction_date).toLocaleDateString("id-ID")}</p>
                      </div>

                      <div className="w-full flex items-start gap-5">
                        <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Jumlah</p>
                        <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                        <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">
                          {" "}
                          {parseFloat(selectedTransaction.gross_amount).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}
                        </p>
                      </div>

                      <div className="w-full flex items-start gap-5">
                        <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Rekening Asal</p>
                        <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                        <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">{selectedTransaction.asal_dana.length > 10 ? "**********" + selectedTransaction.asal_dana.slice(-6) : selectedTransaction.asal_dana}</p>
                      </div>

                      <div className="w-full flex items-start gap-5">
                        <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Rekening Tujuaan</p>
                        <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                        <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">
                          {selectedTransaction.tujuan_dana.length > 10 ? "**********" + selectedTransaction.tujuan_dana.slice(-6) : selectedTransaction.tujuan_dana}
                        </p>
                      </div>

                      <div className="w-full flex items-start gap-5">
                        <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Status</p>
                        <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                        <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">{selectedTransaction.status}</p>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
            </div>
            <button onClick={handleCloseDetail} className=" text-orange-600 border border-orange-600 w-full rounded-full px-4 py-2">
              Tutup
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionProyek;
