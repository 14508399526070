import { createBrowserRouter } from "react-router-dom";
import LandingPageUdin from "../LandingPageUdin/Pages";
import LandingMerchantBisnis from "../LandingPageUdin/Components/merchantBisnis";
import NotFoundPage from "../LandingPageUdin/Components/notFound";
import DashbordUtama from "../paymentgateway/pages/landingpagepayment";
import Register from "../paymentgateway/pages/registrasi";
import Login from "../paymentgateway/pages/login";
import Beranda from "../paymentgateway/pages/beranda";
import BerandaSandbox from "../paymentgateway/pages/beranda sandbox";
import Transaksi from "../paymentgateway/pages/transaksi";
import TransaksiSandbox from "../paymentgateway/pages/transaksi sandbox";
import TransaksiProyek from "../paymentgateway/pages/transaksi-proyek";
import TransaksiProyekSandbox from "../paymentgateway/pages/transaksi-proyek sandbox";
import Saldo from "../paymentgateway/pages/saldo";
import SaldoSandbox from "../paymentgateway/pages/saldo sandbox";
import ChangeNorek from "../paymentgateway/pages/saldo/gantinorek/tambahnorek";
import AllData2 from "../paymentgateway/pages/alldata";
import Profile from "../paymentgateway/pages/profile";
import IpWhitlist from "../paymentgateway/pages/ipwhitlist";
import IpWhitlistSandbox from "../paymentgateway/pages/ipwhitlist sandbox";
import Pin from "../paymentgateway/pages/pin";
import AccsesKey from "../paymentgateway/pages/accses-key";
import Logout from "../paymentgateway/pages/logout";
import SummaryPerusahaan from "../paymentgateway/pages/alldata/dataperusahaan/summary";
import SummaryIndividu from "../paymentgateway/pages/alldata/dataindividu/summary";
import DashboardIndividu from "./DahsboardIndividu";
import ErrorPage from "../LandingPageUdin/Components/pageError";
import Proyek from "../paymentgateway/pages/proyek";
import AddProject from "../paymentgateway/pages/proyek/addProject";
import BankTransferOptions from "../paymentgateway/pages/proyek/pembayaran/bank";
import BankVerification from "../paymentgateway/pages/proyek/pembayaran/bca";
import QrisVerification from "../paymentgateway/pages/proyek/pembayaran/qris";
import BankDetail from "../paymentgateway/pages/proyek/pembayaran/getPengajuan";
import EditProyek from "../paymentgateway/pages/proyek/editProject";
import ProyekAllQris from "../paymentgateway/pages/proyekQrisValid/index";
import ProyekQris from "../paymentgateway/pages/proyek-qris/data_qris";
import ProyekQrisDetail from "../paymentgateway/pages/proyek-qris/detail_qris";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <DashbordUtama />,
    errorElement: <NotFoundPage />,
  },

  // ROUTER PRODUCTION
  {
    path: "me/",
    element: <DashboardIndividu />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
      {
        path: "beranda",
        element: <Beranda />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "beranda-sandbox",
        element: <BerandaSandbox />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "transaksi",
        element: <Transaksi />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "transaksi-sandbox",
        element: <TransaksiSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "transpro",
        element: <TransaksiProyek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "transpro-sandbox",
        element: <TransaksiProyekSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "saldo",
        element: <Saldo />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "saldo-sandbox",
        element: <SaldoSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "saldo/changenorek",
        element: <ChangeNorek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek",
        element: <Proyek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/add",
        element: <AddProject />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/edit",
        element: <EditProyek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/:id",
        element: <BankTransferOptions />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/:id/:id/",
        element: <BankVerification />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/:id/:id/qris",
        element: <QrisVerification />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/detail/:id",
        element: <BankDetail />,
        errorElement: <NotFoundPage />,
      },

      // QRIS INDIVIDU

      {
        path: "validQris",
        element: <ProyekAllQris />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek-qris",
        element: <ProyekQris />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek-qris-detail",
        element: <ProyekQrisDetail />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "data",
        element: <AllData2 />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "summary-perusahaan",
        element: <SummaryPerusahaan />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "summary-individu",
        element: <SummaryIndividu />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "profile",
        element: <Profile />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "ip-whitlist",
        element: <IpWhitlist />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "ip-whitlist-sandbox",
        element: <IpWhitlistSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "pin",
        element: <Pin />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "accses-key",
        element: <AccsesKey />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "logout",
        element: <Logout />,
        errorElement: <NotFoundPage />,
      },
    ],
  },

  // Route Regis & Login
  {
    path: "register",
    element: <Register />,
    errorElement: <NotFoundPage />,
    // children: [
    //   {
    //     path: "/login",
    //     element: <Login />,
    //     errorElement: <NotFoundPage />,
    //   },
    // ],
  },
  {
    path: "login",
    element: <Login />,
    errorElement: <NotFoundPage />,
  },

  // Route UdinPay

  {
    path: "/udin-payment",
    element: <LandingPageUdin />,
    errorElement: <NotFoundPage />,
  },

  {
    path: "/udin-payment-Merchant-Bisnis",
    element: <LandingMerchantBisnis />,
    errorElement: <NotFoundPage />,
  },
]);
