import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

interface Bank {
  _id: string;
  namaBank: string;
}

interface Rekening {
  _id: string;
  user_id: string;
  nama_pemilik_rekening: string;
  bank_id: Bank;
  nomor_rekening: string;
  cabang_bank: string;
  alamat_bank: string;
  __v: number;
}

interface IndividuData {
  _id: string;
  id_user: string;
  nama_individu: string;
  nomor_ktp: string;
  foto_ktp: string;
  no_npwp: string;
  foto_npwp: string;
  rekening: Rekening;
  alamat: string;
  __v: number;
}

const Summary: React.FC = () => {
  const [dataIndividu, setdataIndividu] = useState<IndividuData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false); // State to handle success popup
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isverified, setIsVerified] = useState("");

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 3000); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or if showSuccessModal changes
    }
  }, [showSuccessModal]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}profile-data-individu`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setdataIndividu(response.data.data);
        if (response.data.data.is_verified === "Verifikasi") {
          setIsVerified("Verifikasi");
        } else if (response.data.data.is_verified === "Ditolak") {
          setIsVerified("Ditolak");
        } else {
          setIsVerified("belum disetujui");
        }

        if (response.data.data.id_user.pin === "" || response.data.data.id_user.pin === null) {
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error("Terjadi kesalahan saat mengambil data:", error);
      }
    };

    fetchData();
  }, []);

  const handlePinChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Move to the next input field if the value is entered
      if (value && index < pin.length - 1) {
        inputRefs.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        // Move to the previous input field if the value is deleted
        inputRefs.current[index - 1]?.focus();
      }

      // Enable button if all fields are filled
      if (newPin.every((digit) => digit !== "")) {
        setIsButtonEnabled(true);
      } else {
        setIsButtonEnabled(false);
      }
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      inputRefs.current[0]?.focus();
    }
  }, [isModalOpen]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    const pinString = pin.join("");
    const token = localStorage.getItem("token");

    axios
      .put(
        `${process.env.REACT_APP_API_URL}buat-pin`,
        { pin: pinString },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        // setIsPinSet(true); // Set pin to true when it's successfully set
        setShowSuccessModal(true); // Show success modal
        setIsModalOpen(false); // Close the PIN modal
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengirim PIN:", error);
        // Handle error, maybe show an error message
      });
  };

  if (!dataIndividu) {
    return <div className="flex justify-center text-6xl items-center text-orange-600 font-semibold">....</div>;
  }

  return (
    <section>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="bg-[#FEEFE9]  p-4  w-full mt-10">
          <p className="text-sm text-center text-orange-600">PIN Anda telah berhasil disimpan. Anda dapat melanjutkan proses selanjutnya.</p>
        </div>
      )}
      <div className="p-[50px] bg-white border border-[#DDE5E9] rounded-2xl mb-4 text-start">
        {isverified === "Verifikasi" && (
          <div className="bg-[#E9FEEC] text-[#25EF39] p-3 mb-14 rounded-xl">
            <p className="text-sm ps-3">Data Anda telah diverifikasi dengan sukses. Terima kasih! Anda sekarang dapat melanjutkan ke langkah berikut.</p>
          </div>
        )}

        {isverified === "Ditolak" && (
          <div className="bg-[#fee9e9] text-red-500 p-3 mb-14 rounded-xl">
            <p className="text-sm ps-3">Data anda di tolak mohon periksa beberapa dokumen anda yang tidak sesuai</p>
          </div>
        )}
        {isverified === "belum disetujui" && (
          <div className="bg-[#FEEFE9] text-orange-600 p-3 mb-14 rounded-xl">
            <p className="text-sm ps-3">Verifikasi Sedang Diproses. Mohon tunggu, data Anda sedang dalam proses verifikasi. Ini mungkin memerlukan beberapa saat. Anda dapat mulai mengintegrasikan sistem Anda dengan mode Sandbox kami.</p>
          </div>
        )}
        <div className="flex flex-col gap-7 text-start">
          <div className="flex gap-12">
            <strong className="w-[215px]">Nama Perusahaan</strong> <strong>:</strong> <p className="uppercase">{dataIndividu.nama_individu}</p>
          </div>
          <div className="flex gap-12">
            <strong className="w-[215px]">Nomor KTP </strong> <strong>:</strong> {dataIndividu.nomor_ktp}
          </div>
          <div className="flex gap-12">
            <strong className="w-[215px]">Nomor NPWP</strong> <strong>:</strong> {dataIndividu.no_npwp}
          </div>

          <div className="flex gap-12">
            <strong className="w-[215px]">Rekening</strong> <strong>:</strong> {dataIndividu.rekening.bank_id.namaBank} - {dataIndividu.rekening.nomor_rekening}
          </div>

          <div className="flex gap-12">
            <strong className="w-[215px]">Alamat </strong> <strong>:</strong> {dataIndividu.alamat}
          </div>

          {/* FILE */}

          <div className="flex gap-12">
            <strong className="w-[215px]">File KTP</strong>
            <strong>:</strong>
            <Link to={dataIndividu.foto_ktp} target="_blank">
              <div className="bg-orange-600 px-5 py-1 text-white rounded-lg">Lihat File</div>
            </Link>
          </div>

          <div className="flex gap-12">
            <strong className="w-[215px]">File NPWP</strong>
            <strong>:</strong>
            <Link to={dataIndividu.foto_npwp} target="_blank">
              <div className="bg-orange-600 px-5 py-1 text-white rounded-lg">Lihat File</div>
            </Link>
          </div>
        </div>
      </div>

      {/* Display modal only if PIN is not set */}
      {isModalOpen && (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[400px] ms-64">
            <h2 className="text-[32px] text-[#222831] font-medium mb-4">Buat PIN Baru</h2>
            <div className="flex justify-center space-x-5 my-11">
              {pin.map((digit, index) => (
                <input
                  key={index}
                  type="password"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handlePinChange(e.target.value, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className={`w-4 h-4  text-center text-[#F15A24] text-[24px] font-bold rounded-full ${digit ? "bg-[#F15A24]" : "bg-[#F7F7F7]"} focus:outline-none`}
                />
              ))}
            </div>

            <div className="flex flex-col">
              <button className={`p-3 rounded-full mb-2 ${isButtonEnabled ? "bg-orange-600 text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} disabled={!isButtonEnabled} onClick={handleSubmit}>
                Lanjut
              </button>
              <button className="text-orange-600 mt-1 rounded-full border border-orange-600 p-3" onClick={handleModalClose}>
                Batal
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Summary;
