import React, { useState, useEffect } from "react";
import axios from "axios";

interface DataQris {
  _id: string;
  user_id: string;
  is_verified: string;
  data_user: {
    nama_individu: string;
    nomor_ktp: string;
    email: string;
    no_telp: string;
    tmpt_lahir: string;
    tgl_lahir: string;
    nama_ibu: string;
    alamat: string;
    provinces: string;
    kabupaten: string;
    kecamatan: string;
    kelurahan: string;
    _id: string;
  };
  data_toko: {
    nama_toko: string;
    foto_ktp: string;
    foto_ktp_selfie: string;
    foto_toko_luar: string;
    foto_toko_dalam: string;
    foto_toko_luar_dgn_pemilik: string;
    foto_toko_dalam_dgn_pemilik: string;
    provinces_toko: string;
    kabupaten_toko: string;
    kecamatan_toko: string;
    kelurahan_toko: string;
    alamat_toko: string;
    _id: string;
  };
  status: string;
}

const GetDataQris: React.FC = () => {
  const [companyData, setCompanyData] = useState<DataQris | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [status, setstatus] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}get-pengajuan-qris`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response);
        setCompanyData(response.data.data);
        setLoading(false);
        if (response.data.data.status === "Disetujui") {
          setstatus("Disetujui");
        } else if (response.data.data.status === "Belum disetujui") {
          setstatus("Belum disetujui");
        } else {
          setstatus("Ditolak");
        }
      })
      .catch((err) => {
        setError("Gagal mengambil data.");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p className="text-center text-orange-600">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-red-600">{error}</p>;
  }

  return (
    <>
      <div className="p-10 bg-white border border-[#DDE5E9] rounded-2xl mb-8">
        {status === "Disetujui" && (
          <div className="bg-[#E9FEEC] text-[#25EF39] p-3 mb-14 rounded-xl">
            <p className="text-sm ps-3">Data Anda telah diverifikasi dengan sukses. Terima kasih! Anda sekarang dapat melanjutkan ke langkah berikut.</p>
          </div>
        )}

        {status === "Belum disetujui" && (
          <div className="bg-[#FEEFE9]  text-orange-600 px-4 py-3 rounded-lg mb-12 text-start text-sm">Verifikasi Sedang Diproses. Mohon tunggu, data Anda sedang dalam proses verifikasi. Ini mungkin memerlukan beberapa saat.</div>
        )}

        {status === "Ditolak" && (
          <div className="bg-[#fee9e9] text-red-500 p-3 mb-14 rounded-xl">
            <p className="text-sm ps-3">Data anda di tolak mohon periksa beberapa dokumen anda yang tidak sesuai</p>
          </div>
        )}

        <div className="mt-5">
          <table className="w-full text-left table-fixed">
            <tbody className="flex flex-col gap-6">
              <h1 className="my-4 font-bold text-[22px] text-start">Data Pribadi</h1>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831]  w-[40%]">Nama Lengkap</td>
                <td>:</td>
                <td className="text-[#222831] uppercase"> {companyData?.data_user.nama_individu}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Nama Ibu Kandung</td>
                <td>:</td>
                <td className="text-[#222831] uppercase ">{companyData?.data_user.nama_ibu}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Tempat Lahir</td>
                <td>:</td>
                <td className="text-[#222831] capitalize">{companyData?.data_user.tmpt_lahir}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Tanggal Lahir</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.tgl_lahir}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Nomor KTP</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.nomor_ktp}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Nomor Telepon</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.no_telp}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Provinsi</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.provinces}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Kabupaten</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.kabupaten}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Kecamatan</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.kecamatan}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Kelurahan</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.kelurahan}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Alamat</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_user.alamat}</td>
              </tr>

              {/* ================= DATA TOKO ================= */}

              <h1 className="my-4 font-bold text-[22px] text-start">Data Toko</h1>

              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Nama Toko</td>
                <td>:</td>
                <td className="text-[#222831] uppercase ">{companyData?.data_toko.nama_toko}</td>
              </tr>

              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Provinsi</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_toko.provinces_toko}</td>
              </tr>

              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Kabupaten</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_toko.kabupaten_toko}</td>
              </tr>

              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Kecamatan</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_toko.kecamatan_toko}</td>
              </tr>

              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">kelurahan</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_toko.kelurahan_toko}</td>
              </tr>

              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">Alamat</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.data_toko.alamat_toko}</td>
              </tr>

              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">File KTP</td>
                <td>:</td>
                <td className="bg-orange-600 rounded-lg py-1 px-3 text-white">
                  <a href={companyData?.data_toko.foto_ktp} target="_blank" rel="noopener noreferrer" className="no-underline">
                    Lihat File
                  </a>
                </td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">File Foto Selfie</td>
                <td>:</td>
                <td className="bg-orange-600 rounded-lg py-1 px-3 text-white">
                  <a href={companyData?.data_toko.foto_ktp_selfie} target="_blank" rel="noopener noreferrer" className="no-underline">
                    Lihat File
                  </a>
                </td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">File Foto Toko ( Dari Luar ) </td>
                <td>:</td>
                <td className="bg-orange-600 rounded-lg py-1 px-3 text-white">
                  <a href={companyData?.data_toko.foto_toko_luar} target="_blank" rel="noopener noreferrer" className="no-underline">
                    Lihat File
                  </a>
                </td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%]">File Foto Toko ( Dari Dalam )</td>
                <td>:</td>
                <td className="bg-orange-600 rounded-lg py-1 px-3 text-white">
                  <a href={companyData?.data_toko.foto_toko_dalam} target="_blank" rel="noopener noreferrer" className="no-underline">
                    Lihat File
                  </a>
                </td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">File Foto Toko ( Dari Luar Dengan Pemilik )</td>
                <td>:</td>
                <td className="bg-orange-600 rounded-lg py-1 px-3 text-white">
                  <a href={companyData?.data_toko.foto_toko_luar_dgn_pemilik} target="_blank" rel="noopener noreferrer" className="no-underline">
                    Lihat File
                  </a>
                </td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[40%] ">File Foto Toko ( Dari Dalam Dengan Pemilik)</td>
                <td>:</td>
                <td className="bg-orange-600 rounded-lg py-1 px-3 text-white">
                  <a href={companyData?.data_toko.foto_toko_luar_dgn_pemilik} target="_blank" rel="noopener noreferrer" className="no-underline">
                    Lihat File
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GetDataQris;
