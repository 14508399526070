import React, { useState, useEffect } from "react";
import UbahTeleponPopup from "./phonechange";
import PopupChangePassword from "./pwchange";
import axiosInstance from "../../../../services/axiosInstance";

const ProfilPerusahaan: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}get-profile`)
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        console.error("Gagal mengambil data pengguna:", error);
        if (error.response?.status === 401) {
          localStorage.removeItem("token");
        }
      });
  }, []);

  const handlePopupOpen = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file)); // Generate preview
    }
  };

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profile_pict", selectedFile);

      axiosInstance
        .put(`${process.env.REACT_APP_API_URL}profile`, formData, {})
        .then((response) => {
          setProfileData({ ...profileData, users: { ...profileData.users, profile_pict: response.data.profile_pict } });

          window.location.reload(); // Auto-refresh after success
        })
        .catch((error) => {
          console.error("Gagal memperbarui foto:", error);
          alert("Gagal memperbarui foto");
        });
    }
  };

  if (!profileData) {
    return <div className="flex justify-center text-6xl items-center text-orange-600 font-semibold">....</div>;
  }

  return (
    <section className="">
      <div className="flex flex-col mb-7 border border-[#DDE5E9] rounded-xl w-full space-y-10 px-8 py-12">
        {/* PROFIL */}
        <div className="flex justify-start gap-6 items-center">
          <div className="rounded-full bg-cover bg-inherit">
            {/* If preview exists, show preview, else show profile picture */}
            <img src={preview || profileData.users.profile_pict} alt="" className="rounded-full w-24 h-24" />
          </div>
          <div className="text-start">
            <p className="text-xl font-semibold uppercase">{profileData.users.username}</p>
            <label htmlFor="upload-photo" className="text-orange-600 mt-3 cursor-pointer">
              Edit Foto
            </label>
            <input type="file" id="upload-photo" style={{ display: "none" }} accept="image/*" onChange={handleFileChange} />
          </div>
        </div>

        {/* Other parts of the component */}
        {/* DATA */}
        <div>
          <h2 className="text-xl font-semibold text-start mt-6">Data</h2>
          <div className="flex lg:gap-32 xl:gap-80 mt-5">
            <div className="flex flex-col gap-5 text-start w-full">
              <label htmlFor="" className="font-medium">
                Legalitas
              </label>
              <span className="bg-[#F9F9FA] py-4 px-3 text-start ms-1 w-full text-[#222831] rounded-lg cursor-not-allowed uppercase">{profileData.users.legalitas}</span>
            </div>

            <div className="flex flex-col gap-5 text-start w-full">
              <label htmlFor="" className="font-medium">
                Nama Lengkap
              </label>
              <span className="bg-[#F9F9FA] py-4 px-3 text-start ms-1 w-full text-[#222831] rounded-lg cursor-not-allowed uppercase">{profileData.users.username}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5 text-start mt-7 cursor-not-allowed">
          <label htmlFor="" className="font-medium">
            Email
          </label>
          <div className="flex justify-between">
            <span className="bg-[#F9F9FA] py-4 px-3 text-start ms-1 w-full text-[#222831] rounded-lg">{profileData.users.email}</span>
          </div>
        </div>

        <div className="flex flex-col gap-5 text-start mt-7">
          <label htmlFor="" className="font-medium">
            Nomor Telepon
          </label>
          <div className="flex justify-between bg-[#F9F9FA] py-4 px-3 text-start ms-1 w-full rounded-xl">
            <span className="text-[#222831]">{profileData.users.no_telp}</span>
            <button className="text-orange-600" onClick={handlePopupOpen}>
              Ubah Nomor Telepon
            </button>
          </div>
        </div>

        <div className="flex flex-col gap-5 text-start mt-7">
          <h2 className="text-xl font-semibold text-start mt-6">Password</h2>

          <div className="flex justify-between items-center bg-[#F9F9FA] py-2 px-3 ms-1 w-[413px] text-[#222831] rounded-md">
            <span className="bg-[#F9F9FA] py-1 px-3 text-start ms-1 w-full text-[#222831] cursor-not-allowed">*******</span>
          </div>
          <button onClick={() => setIsPopupOpen(true)} className="bg-[#F15A24] px-5 py-2 w-[200px] text-sm rounded-full text-white">
            Ubah Password
          </button>
        </div>
      </div>

      {selectedFile && (
        <div className="flex justify-end mb-5 text-center">
          <button className=" text-white bg-orange-600 px-28 py-3 rounded-full flex justify-end text-center" onClick={handleFileUpload}>
            Simpan
          </button>
        </div>
      )}
      {isPopupVisible && <UbahTeleponPopup onClose={handlePopupClose} />}
      {isPopupOpen && <PopupChangePassword onClose={() => setIsPopupOpen(false)} />}
    </section>
  );
};

export default ProfilPerusahaan;
