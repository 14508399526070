import React, { useState, useEffect } from "react";
import { cloudfile, pdficon, imagered } from "../../../assets/icon";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { IconChevron } from "../icon";
import { useParams } from "react-router-dom";

// Define the types for each region
interface Province {
  id: string;
  name: string;
}

interface Kabupaten {
  id: string;
  name: string;
}

interface Kecamatan {
  id: string;
  name: string;
}

interface Kelurahan {
  id: string;
  name: string;
}

const DataPerusahaan: React.FC = () => {
  const { id } = useParams();
  const [nama_lengkap, setnama_lengkap] = useState("");
  const [nama_ibu, setnama_ibu] = useState("");

  const [no_ktp, setno_ktp] = useState("");
  const [tmpt_lahir, setmpt_lahir] = useState("");
  const [tgl_lahir, setgl_lahir] = useState("");

  const [foto_ktp, setfoto_ktp] = useState<File | null>(null);
  const [file_akta, setfile_akta] = useState<string>(""); // URL for the akta file from the backend
  // URL for the nib file from the backend

  // State for region data with correct types
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [kabupaten, setKabupaten] = useState<Kabupaten[]>([]);
  const [kecamatan, setKecamatan] = useState<Kecamatan[]>([]);
  const [kelurahan, setKelurahan] = useState<Kelurahan[]>([]);

  // State for selected values
  const [selectedProvince, setSelectedProvince] = useState<string>("");
  const [selectedKabupaten, setSelectedKabupaten] = useState<string>("");
  const [selectedKecamatan, setSelectedKecamatan] = useState<string>("");
  const [selectedKelurahan, setSelectedKelurahan] = useState<string>("");

  // UPLOAD PROGRES

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);

  const isFormValid = () => nama_lengkap && nama_ibu && no_ktp.length === 16 && tgl_lahir && tmpt_lahir;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data.data;
        setno_ktp(data.no_ktp || "");

        // Assuming file URLs are returned by the backend:
        setfile_akta(data.file_akta_perusahaan || "");
      })
      .catch((error) => {
        console.error("Error fetching company profile data:", error);
      });
  }, []);

  // Fetch provinces on component mount
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}provinsi`)
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setProvinces(response.data.data);
        } else {
          console.error("Invalid data format for provinces");
        }
        // const id = response.data.data.id;
      })
      .catch((error) => console.error("Error fetching provinces:", error));
  }, []);

  // Fetch kabupaten when a province is selected
  useEffect(() => {
    if (selectedProvince) {
      axios
        .get(`${process.env.REACT_APP_API_URL}provinsi/${selectedProvince}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].regencies)) {
            setKabupaten(response.data.data[0].regencies); // Ambil regencies langsung dari respons
            setKecamatan([]); // Clear kecamatan ketika provinsi berubah
            setKelurahan([]); // Clear kelurahan ketika provinsi berubah
          } else {
            console.error("Invalid data format for kabupaten");
            console.log(response);
          }
        })
        .catch((error) => console.error("Error fetching kabupaten:", error));
    }
  }, [selectedProvince]);

  // Fetch kecamatan when a kabupaten is selected
  useEffect(() => {
    if (selectedKabupaten) {
      axios
        .get(`${process.env.REACT_APP_API_URL}regency/${selectedKabupaten}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].districts)) {
            setKecamatan(response.data.data[0].districts);
            setKelurahan([]); // Clear kelurahan when kabupaten changes
          } else {
            console.error("Invalid data format for kecamatan");
            console.log(response);
          }
        })
        .catch((error) => console.error("Error fetching kecamatan:", error));
    }
  }, [selectedKabupaten]);

  // Fetch kelurahan when a kecamatan is selected
  useEffect(() => {
    if (selectedKecamatan) {
      axios
        .get(`${process.env.REACT_APP_API_URL}district/${selectedKecamatan}`)
        .then((response) => {
          if (Array.isArray(response.data.data[0].villages)) {
            setKelurahan(response.data.data[0].villages);
          } else {
            console.error("Invalid data format for kelurahan");
            console.log(response);
          }
        })
        .catch((error) => console.error("Error fetching kelurahan:", error));
    }
  }, [selectedKecamatan]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFile(file);
    }
  };

  const uploadFile = (file: File) => {
    setIsUploading(true);
    const fakeUpload = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const formData = new FormData();
    formData.append("nama_lengkap", nama_lengkap);
    formData.append("nama_ibu", nama_ibu);
    formData.append("no_ktp", no_ktp);

    formData.append("tmpt_lahir", tmpt_lahir);
    formData.append("tgl_lahir", tgl_lahir);

    // Use URLs directly since we're not allowing file re-upload
    formData.append("file_akta", file_akta);

    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}request-akses-pembayaran/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data) {
          navigate(`/me/proyek/pembayaran/${id}`);
        }
      })
      .catch((error) => {
        alert("gagal mengirim data");
        console.error("Gagal mengirim data:", error);
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const isVerified = localStorage.getItem("is_verified");
    if (isVerified === "true") {
      navigate("");
    }
  }, [navigate]);

  return (
    <section>
      <div className="mb-10 p-[50px] bg-white border border-[#DDE5E9] rounded-2xl">
        <Link to="/me/proyek">
          <div className="w-[100px] flex gap-[20px] cursor-pointer">
            <IconChevron />
            <p className="text-[18px] mt-[-5px] font-semibold text-start mb-10">Kembali</p>
          </div>
        </Link>

        <h2 className="text-xl font-bold mb-4 text-start">Data Pribadi</h2>

        {/* Nama Lengkap & ktp */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nama Lengkap</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase "
              value={nama_lengkap}
              placeholder="Masukan Nama Lengkap"
              onChange={(e) => setnama_lengkap(e.target.value)}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Nomor KTP</label>
            <input type="number" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-not-allowed " value={no_ktp} onChange={(e) => setno_ktp(e.target.value.slice(0, 16))} readOnly />
          </div>
        </div>

        {/*Email & Tgl No Telepon*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Email</label>
            <input type="text" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase " value={tmpt_lahir} placeholder="Masukan ID Outlet" onChange={(e) => setmpt_lahir(e.target.value)} />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">No Telepon</label>
            <input type="number" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]  " value={tgl_lahir} onChange={(e) => setgl_lahir(e.target.value.slice(0, 16))} />
          </div>
        </div>

        {/*Tempat Lahir & Tgl Lahir*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Tempat Lahir</label>
            <input type="text" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase " value={tmpt_lahir} placeholder="Masukan ID Outlet" onChange={(e) => setmpt_lahir(e.target.value)} />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Tanggal Lahir</label>
            <input type="date" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]  " value={tgl_lahir} onChange={(e) => setgl_lahir(e.target.value.slice(0, 16))} />
          </div>
        </div>

        {/*No Ibu Kandung */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nama Ibu Kandung</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase "
              value={nama_ibu}
              placeholder="Masukan Nama Ibu Kandung"
              onChange={(e) => setnama_ibu(e.target.value)}
            />
          </div>
        </div>

        {/* Daerah  */}
        {/* Provinces & kab*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Provinsi</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedProvince} onChange={(e) => setSelectedProvince(e.target.value)}>
              <option value="">Pilih Provinsi</option>
              {provinces.map((province) => (
                <option key={province.id} value={province.id}>
                  {province?.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kabupaten */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kabupaten</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedKabupaten} onChange={(e) => setSelectedKabupaten(e.target.value)} disabled={!selectedProvince}>
              <option value="">Pilih Kabupaten</option>
              {kabupaten.map((kab) => (
                <option key={kab.id} value={kab.id}>
                  {kab?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Kecamatan */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kecamatan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedKecamatan} onChange={(e) => setSelectedKecamatan(e.target.value)} disabled={!selectedKabupaten}>
              <option value="">Pilih Kecamatan</option>
              {kecamatan.map((kec) => (
                <option key={kec.id} value={kec.id}>
                  {kec.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kelurahan */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kelurahan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedKelurahan} onChange={(e) => setSelectedKelurahan(e.target.value)} disabled={!selectedKecamatan}>
              <option value="">Pilih Kelurahan</option>
              {kelurahan.map((kel) => (
                <option key={kel.id} value={kel.id}>
                  {kel.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Alamat */}
        <div className="mb-4 w-full">
          <label className="block text-gray-700 text-start">Alamat</label>
          <textarea className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]"></textarea>
        </div>

        {/* ============================================ DATA TOKO ================================================== */}

        <h1 className="font-bold my-9 text-xl text-start">Data Toko</h1>

        {/* UNGGAH KTP TOKO  */}
        <div className="mb-7 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Unggah KTP </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgress}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_ktp(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChange(e, setfoto_ktp)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {foto_ktp && !isUploading && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm w-[200px] truncate">{foto_ktp.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_ktp(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>

          {/* UNGGAH FOTO SELFIE */}
          <div className="w-full">
            <label className="block text-gray-700">Unggah foto selfie dengan KTP</label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgress}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_ktp(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChange(e, setfoto_ktp)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] text-sm mt-1">*Catatan: silakan lakukan unggah Gambar, Max 10 MB</p>
            {foto_ktp && !isUploading && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {imagered()}
                  <p className="text-gray-700 text-sm w-[200px] truncate">{foto_ktp.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_ktp(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* FOTO SELFI & LOKASI TOKO */}
        <div className="mb-8 flex gap-10 justify-between text-start">
          {/* UNGGAH FOTO TOKO */}
          <div className="w-full">
            <label className="block text-gray-700">Unggah Lokasi Toko </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgress}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_ktp(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChange(e, setfoto_ktp)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] text-sm mt-1">*Catatan: silakan lakukan unggah Gambar, Max 10 MB</p>
            {foto_ktp && !isUploading && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {imagered()}
                  <p className="text-gray-700 text-sm w-[200px] truncate">{foto_ktp.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_ktp(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Nama Toko*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nama Toko</label>
            <input
              type="text"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize uppercase "
              value={nama_lengkap}
              placeholder="Masukan Nama Lengkap"
              onChange={(e) => setnama_lengkap(e.target.value)}
            />
          </div>
        </div>

        {/* Daerah  */}
        {/* Provinces & kab*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Provinsi</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedProvince} onChange={(e) => setSelectedProvince(e.target.value)}>
              <option value="">Pilih Provinsi</option>
              {provinces.map((province) => (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kabupaten */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kabupaten</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedKabupaten} onChange={(e) => setSelectedKabupaten(e.target.value)} disabled={!selectedProvince}>
              <option value="">Pilih Kabupaten</option>
              {kabupaten.map((kab) => (
                <option key={kab.id} value={kab.id}>
                  {kab.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Kecamatan */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kecamatan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedKecamatan} onChange={(e) => setSelectedKecamatan(e.target.value)} disabled={!selectedKabupaten}>
              <option value="">Pilih Kecamatan</option>
              {kecamatan.map((kec) => (
                <option key={kec.id} value={kec.id}>
                  {kec.name}
                </option>
              ))}
            </select>
          </div>

          {/* Kelurahan */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Kelurahan</label>
            <select className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl" value={selectedKelurahan} onChange={(e) => setSelectedKelurahan(e.target.value)} disabled={!selectedKecamatan}>
              <option value="">Pilih Kelurahan</option>
              {kelurahan.map((kel) => (
                <option key={kel.id} value={kel.id}>
                  {kel.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Alamat */}
        <div className="mb-4 w-full">
          <label className="block text-gray-700 text-start">Alamat</label>
          <textarea className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]"></textarea>
        </div>

        <button onClick={handleVerify} className={`flex justify-start mt-10 py-2 px-14 bg-orange-600 text-white rounded-full font-semibold ${!isFormValid() && "bg-slate-200 cursor-not-allowed"}`} disabled={!isFormValid()}>
          Lanjut Verifikasi
        </button>
      </div>
    </section>
  );
};

export default DataPerusahaan;
