import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Succsesimg from "../../../assets/images/succses.png";
import { AppDispatch, RootState } from "../../../features/stores";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../features/reducers/profileSlice";

interface PopupProps {
  onClose: () => void;
}

const UbahTeleponPopup: React.FC<PopupProps> = ({ onClose }) => {
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [oldPhoneNumber, setOldPhoneNumber] = useState<string>("");
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>("");
  const [step, setStep] = useState<"pin" | "oldPhone" | "otp" | "newPhone" | "success">("pin");
  const [errorMessage, setErrorMessage] = useState("");
  const [accsesToken, setaccsesToken] = useState("");
  const [canResend, setCanResend] = useState(false);
  const pinInputsRef = useRef<HTMLInputElement[]>([]);
  const otpRefs = useRef<HTMLInputElement[]>([]);
  const [timeLeft, setTimeLeft] = useState(300);

  const token = localStorage.getItem("token"); // Ganti dengan token Anda

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const handlePinChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return;

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    // Clear error message when input changes
    setErrorMessage("");

    // Cek apakah user memiliki PIN
    if (user?.pin === null) {
      setErrorMessage("Anda belum mempunyai PIN, ambil dari user?.pin");
      return;
    }

    if (value && index < 5) {
      pinInputsRef.current[index + 1]?.focus();
    }

    if (newPin.every((digit) => digit !== "")) {
      // console.log(newPin.join(""));
      // Kirim PIN ke backend
      axios
        .post(`${process.env.REACT_APP_API_URL}pin`, { pin: newPin.join("") }, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data) {
            setStep("oldPhone");
          } else {
            setErrorMessage("PIN Tidak Valid.");
          }

          if (user?.pin === null) {
            setErrorMessage("Anda belum mempunyai PIN, ambil dari user?.pin");
            return;
          }
          // console.log(response.data);
        })
        .catch((error) => {
          console.error("Error validating PIN:", error);
          setErrorMessage("PIN Tidak Valid.");
        });
    }
  };

  const handlePinBackspace = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && !pin[index] && index > 0) {
      pinInputsRef.current[index - 1]?.focus();
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Ganti semua angka di awal dengan "+62"
    if (/^\d/.test(value)) {
      value = "+62" + value.replace(/^\d+/, "");
    }

    // Pastikan hanya angka yang diizinkan
    value = value.replace(/[^0-9+]/g, "");

    setOldPhoneNumber(value);

    setErrorMessage(""); // Clear error message on input change
  };

  const handlePhoneChangeNew = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Ganti semua angka di awal dengan "+62"
    if (/^\d/.test(value)) {
      value = "+62" + value.replace(/^\d+/, "");
    }

    // Pastikan hanya angka yang diizinkan
    value = value.replace(/[^0-9+]/g, "");

    setNewPhoneNumber(value);
    setErrorMessage(""); // Clear error message on input change
  };

  useEffect(() => {
    // Fokus pada input PIN pertama ketika komponen dirender
    if (pinInputsRef.current[0]) {
      pinInputsRef.current[0].focus();
    }
  }, []); // Kosongkan dependensi untuk menjalankan efek sekali

  // === OLD NO PHONE=== //
  const handleOldPhoneSubmit = () => {
    console.log({ no_telp: oldPhoneNumber });
    axios
      .post(`${process.env.REACT_APP_API_URL}send-otp`, { no_telp: oldPhoneNumber }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setStep("otp");
        setaccsesToken(response.data.accessToken);
      })
      .catch((error) => {
        console.error("", error);
        setErrorMessage("No Telepon Tidak Valid.");
      });
  };

  const handleOtpChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setErrorMessage("");

      // Move to the next input field automatically
      if (value !== "" && index < otp.length - 1) {
        otpRefs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        otpRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleOtpSubmit = () => {
    fetch(`${process.env.REACT_APP_API_URL}verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accsesToken}`, // Attach the accessToken
      },
      body: JSON.stringify({ otp: otp.join("") }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setStep("newPhone");

          setErrorMessage("");
        } else {
          setErrorMessage(data.message || "Kode OTP tidak valid.");
        }
      })
      .catch((error) => {
        setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti.");
      });
  };

  // === NEW PHONE === //
  const handleNewPhoneSubmit = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}update-phone`, { no_telp: newPhoneNumber }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.data) {
          setStep("success");
        }

        // console.log(response);
      })
      .catch((error) => {
        console.error("Error validating new phone OTP:", error);
        alert("terjadi kesalahan");
      });
  };

  const resendOtp = () => {
    if (canResend) {
      setCanResend(false); // Memastikan pengguna tidak bisa mengirim ulang OTP terus menerus
      setErrorMessage("");
      axios
        .post(`${process.env.REACT_APP_API_URL}send-otp`, { no_telp: oldPhoneNumber }, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setCanResend(false);
          setStep("otp");
          setaccsesToken(response.data.accessToken);
          setErrorMessage(""); // Membersihkan pesan error jika ada
        })
        .catch((error) => {
          console.error("Error: ", error);
          setErrorMessage("Gagal mengirim ulang OTP. Silakan coba lagi nanti.");
        });
    }
  };

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!canResend && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setCanResend(true); // Aktifkan kembali tombol kirim ulang setelah timer habis
      setTimeLeft(300); // Atur ulang waktu timer
    }

    return () => clearInterval(timer); // Bersihkan interval saat komponen di-unmount
  }, [canResend, timeLeft]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60); // Hitung menit tersisa
    const seconds = time % 60; // Hitung detik tersisa
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-20 ms-64">
      <div ref={popupRef} className="bg-white rounded-3xl p-10 w-[400px]">
        {step === "pin" ? (
          // MASUKAN PIN
          <>
            <div>
              {!user?.pin ? (
                // Jika user belum mempunyai PIN, tampilkan pesan error
                <>
                  <div className="flex flex-col justify-center text-center">
                    <p className="text-red-600">Anda belum mempunyai PIN, silahkan Buat PIN terlebih dahulu</p>
                    <Link to="/me/pin">
                      <div className="rounded-full border bg-orange-600  text-white px-5 py-2 mt-6">Buat PIN</div>
                    </Link>
                  </div>
                </>
              ) : (
                // Jika user sudah mempunyai PIN, tampilkan input PIN
                <>
                  <h2 className="text-[32px] font-medium mb-4">Masukkan PIN</h2>
                  <div className="flex justify-center my-11 gap-4">
                    {pin.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (pinInputsRef.current[index] = el!)}
                        type="password"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handlePinChange(index, e.target.value)}
                        onKeyDown={(e) => handlePinBackspace(index, e)}
                        className={`w-4 h-4  text-center text-[#F15A24] text-[24px] font-bold rounded-full ${digit ? "bg-[#F15A24]" : "bg-[#F7F7F7]"} focus:outline-none`}
                      />
                    ))}
                  </div>
                  <p className="text-orange-600 text-center mt-4 cursor-pointer text-sm">
                    <span className="text-[#85898C]">Ingat PIN anda ? </span>
                    <Link to="/me/pin">Lupa PIN</Link>
                  </p>
                  <div className="flex flex-col gap-2 justify-center">
                    {errorMessage && <p className="text-red-500 text-sm  text-center mt-2">PIN Tidak Valid</p>}
                    <button onClick={onClose} className="border border-[#F15A24] rounded-full w-full text-[#F15A24]  py-3 mt-4">
                      Batal
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        ) : step === "oldPhone" ? (
          // NO TELPON LAMA
          <>
            <h2 className="text-[28px] text-[#222831] font-semibold mb-4">
              Masukkan Nomor <br /> Telepon Lama
            </h2>
            <input type="text" maxLength={15} value={oldPhoneNumber} onChange={handlePhoneChange} className="bg-[#F9F9FA] rounded-2xl p-4 w-full mb-4 placeholder:text-[#BFBFBF]" placeholder="Masukkan nomor telepon lama" />
            <div className="flex flex-col gap-4">
              {errorMessage && <p className="text-red-500 text-sm  text-center">No Telepon tidak valid</p>}
              <button onClick={handleOldPhoneSubmit} className={`bg-orange-600 w-full mt-6 text-white p-4 rounded-full ${oldPhoneNumber === "" ? " text-[#BFBFBF] cursor-not-allowed bg-slate-100" : ""}`} disabled={oldPhoneNumber === ""}>
                Lanjutkan
              </button>
            </div>
          </>
        ) : step === "otp" ? (
          // MASUKAN OTP
          <>
            <div className="p-4 flex flex-col justify-center">
              <div>
                <h2 className="text-[32px] font-semibold mb-4">Kode OTP</h2>
                <p className="text-[#6D7588] mb-4 text-[18px]">Masukkan Kode OTP Anda</p>
                <p className="text-[#404040] mb-6 text-[14px]">Kode OTP dikirim ke email Anda </p>
                <div className="flex justify-center gap-2 mb-4">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      ref={(el) => (otpRefs.current[index] = el!)} // Assign refs to each input
                      type="text"
                      maxLength={1}
                      className="w-[40px] h-[45px] text-center border-[1.5px] focus:border-orange-600 border-orange-600 rounded-xl mx-1 bg-[#FEEFE9]"
                      value={value}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                    />
                  ))}
                </div>
                <div className="flex flex-col">
                  <p className="text-orange-600 text-sm mb-5 font-semibold">{formatTime(timeLeft)} S</p>
                  <button onClick={resendOtp} className={`text-orange-600 mb-1 font-medium ${canResend ? "" : "text-slate-200 cursor-not-allowed"}`} disabled={!canResend}>
                    Kirim Ulang Kode OTP
                  </button>
                </div>
                <div className="mt-5">
                  <button className="w-full p-3 rounded-full text-white font-semibol bg-orange-600" onClick={handleOtpSubmit}>
                    Lanjut
                  </button>
                </div>
              </div>
              {errorMessage && <p className="text-red-500 text-sm text-center mt-4">{errorMessage}</p>}
            </div>
          </>
        ) : step === "newPhone" ? (
          // MASUKAN NO TELPON BARU
          <>
            <h2 className="text-[28px] text-[#222831] font-semibold mb-4">
              Masukkan Nomor <br /> Telepon Baru
            </h2>

            <input type="text" maxLength={15} value={newPhoneNumber} onChange={handlePhoneChangeNew} className="bg-[#F9F9FA] rounded-2xl p-4 w-full mb-4 placeholder:text-[#BFBFBF]" placeholder="Masukkan nomor telepon lama" />
            <div className="flex justify-between">
              <button
                onClick={handleNewPhoneSubmit}
                className={`bg-orange-600 w-full mt-6 p-4 text-white rounded-full ${newPhoneNumber === "" ? "bg-slate-200 opacity-50 text-[#BFBFBF] cursor-not-allowed" : ""}`}
                disabled={newPhoneNumber === ""}>
                Lanjutkan
              </button>
            </div>
          </>
        ) : (
          // POPUP BERHASIL
          <>
            <div className="flex justify-center text-center mb-5">
              <img src={Succsesimg} alt="berhasil" />
            </div>

            <p className="text-[#F15A24] text-center text-medium mb-4">Perubahan Nomor Telepon Baru Berhasil</p>
            <button onClick={onClose} className="border border-[#F15A24] text-[#F15A24] w-full mt-1 px-4 py-3 rounded-full">
              Tutup
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default UbahTeleponPopup;
