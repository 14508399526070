import React, { useEffect, useState, useCallback } from "react";
import { plus, edit, editGray } from "../../assets/icon";
import { RootState, useAppDispatch } from "../../features/stores";
import { Link, useNavigate } from "react-router-dom";
import { ProjectData } from "../../features/interface/proyek.interface";
import { useSelector } from "react-redux";
import { fetchProjectData, setSelectedProjectId } from "../../features/reducers/proyekSlice";

const Proyek: React.FC = () => {
  const dispatch = useAppDispatch();
  const { projectData, status } = useSelector((state: RootState) => state.project);
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchProjectData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (projectData && Array.isArray(projectData)) {
      setProjects(projectData);
    }
  }, [projectData]);

  const handleEdit = useCallback(
    (id: string) => {
      dispatch(setSelectedProjectId(id));
      navigate(`edit`);
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (projects.length === 0 && (!projectData || projectData.length === 0)) {
      navigate("add");
    }
  }, [projects, projectData, navigate]);
  return (
    <section className="">
      <div className="flex justify-end">
        <Link to={"add"}>
          <button className="px-6 py-3 mt-4 bg-gradient-to-r from-[#EF2725] via-[#EF2725] to-[#F26023] text-white rounded-xl flex gap-3 items-center">
            {plus()}
            Tambah Proyek
          </button>
        </Link>
      </div>

      <div className="">
        <table className="w-full lg:overflow-x-auto xl:overflow-visible border border-[#DDE5E9] mt-6 mb-6 font-normal text-sm ">
          <thead>
            <tr className="text-[#85898C] font-normal">
              <th className="px-5 py-3 font-normal">ID Merchant</th>
              <th className="px-5 py-3 font-normal">Nama Proyek</th>
              <th className="px-5 py-3 font-normal">Callback URL</th>
              <th className="px-5 py-3 font-normal">Website URL</th>
              <th className="px-5 py-3 font-normal">Status</th>
              <th className="px-5 py-3 font-normal">Aksi</th>
            </tr>
          </thead>
          {status === "loading" ? (
            <tbody>
              <tr>
                <td colSpan={6} className="text-center py-4">
                  <div className="loader">Loading...</div> {/* Replace with an actual loader */}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {projects.map((project, index) => (
                <tr key={index} className="border py-2 font-semibold">
                  <td className="px-4 py-2 truncate max-w-[150px]">{project.kode_project}</td>
                  <td className="px-4 py-2 truncate max-w-[150px] uppercase">{project.nama_project}</td>
                  <td className="px-4 py-2 truncate max-w-[150px]">{project.callback_url}</td>
                  <td className="px-4 py-2 truncate max-w-[150px]">{project.website_url}</td>
                  <td className="px-4 py-2 truncate max-w-[150px]">
                    <span
                      className={`py-1 px-3 rounded-full text-xs ${
                        project.status === "Ditolak"
                          ? "text-[#F12424] bg-[#FDDEDE]" // Red color for "ditolak" status
                          : project.status === "Disetujui"
                          ? "text-[#7EF124] bg-[#DEFDE1]" // Green color for "Aktif" status
                          : "text-[#F1AB24] bg-[#FDF6DE]" // Default color for other statuses
                      }`}>
                      {project.status}
                    </span>
                  </td>

                  <td className={`px-4 py-2 ${project.status === "Belum disetujui" || project.status === "Ditolak" ? "text-gray-400 cursor-not-allowed" : "text-orange-600 cursor-pointer"}`}>
                    {project.status === "Belum disetujui" || project.status === "Ditolak" ? (
                      <span>Sistem Pembayaran</span> // Hanya teks, tidak bisa diklik
                    ) : (
                      <Link to={`pembayaran/${project?._id}`}>Sistem Pembayaran</Link> // Bisa diklik
                    )}
                  </td>

                  <td className="px-4 py-2">
                    <div className="flex gap-3">
                      <button
                        onClick={project.status !== "Ditolak" && project.status !== "Belum disetujui" ? () => handleEdit(project._id) : undefined}
                        className={project.status === "Ditolak" || project.status === "Belum disetujui" ? "cursor-not-allowed" : "cursor-pointer"}>
                        {project.status === "Ditolak" || project.status === "Belum disetujui" ? editGray() : edit()}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </section>
  );
};

export default Proyek;
