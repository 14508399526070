import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../paymentgateway/components/sidebar";
import { Outlet } from "react-router-dom";
import NavbarIndividu from "../../paymentgateway/components/NavbarIndividu";

const DashboardIndividu: React.FC = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);
  return (
    <div className="max-w-[1700px] mx-auto h-full">
      <div className="flex">
        <div className="fixed z-30 bg-white h-full  ">
          <Sidebar />
        </div>

        <div className="z-20   fixed w-auto ">
          <NavbarIndividu />
        </div>
        <div className="w-full lg:ml-[280px]  xl:ml-[315px] mt-[100px] bg-white  mx-[30px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardIndividu;
